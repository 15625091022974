const formatDate = (sqlDate) => {
  // Create a new Date object using the datetime string
  const dateObj = new Date(sqlDate);

  // Extract the relevant parts from the Date object
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-based
  const day = dateObj.getDate().toString().padStart(2, '0');
  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  const seconds = dateObj.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
const timePassed = (createdAt) => {
  const date1 = new Date(createdAt);
  // Using the Date.parse() method
  const date2 = new Date(Date.parse(date1));
  // Create a Date object representing a specific time
  const created = new Date(date2); // June 20, 2023, 10:30:00
  // Get the current time
  const currentTime = new Date();
  // Calculate the time difference in milliseconds
  const timePassed = currentTime - created;
  // Convert the time difference to a human-readable format
  const secondsPassed = Math.floor(timePassed / 1000);
  const minutesPassed = Math.floor(secondsPassed / 60);
  const hoursPassed = Math.floor(minutesPassed / 60);
  const daysPassed = Math.floor(hoursPassed / 24);
  return `${daysPassed} days, ${hoursPassed % 24} hours Passed`;
//   return `${daysPassed} days, ${hoursPassed % 24} hours, ${
//     minutesPassed % 60
//   } minutes, ${secondsPassed % 60} seconds`;
};
export { formatDate, timePassed };

const options = [
    { label: 'Hat / Cap Front ', value: 'Hat / Cap Front' },
    { label: 'Hat / Cap Back ', value: 'Hat / Cap Back' },
    { label: 'Hat / Cap Side ', value: 'Hat / Cap Side' },
    { label: 'Visor / Vizor ', value: 'Visor / Vizor' },
    { label: 'Beanie ', value: 'Beanie' },
    { label: ' Left Chest', value: 'Left Chest' },
    { label: ' Left Breast', value: 'Left Breast' },
    { label: ' Sleeve', value: 'Sleeve' },
    { label: ' Full Back', value: 'Full Back' },
    { label: ' Apron', value: 'Apron' },
    { label: 'Towel', value: 'Towel' },
    { label: 'Blanket', value: 'Blanket' },
    { label: 'Other', value: 'Other' },
  ];

  export default options
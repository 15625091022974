import * as XLSX from 'xlsx';

const jsonToExcel = (jsonData, fileName = 'data') => {
    // Customize column headers
    // const headers = ['ID', 'Name', 'Age'];

    // Convert JSON to worksheet with custom headers
    // const worksheet = XLSX.utils.json_to_sheet(jsonData, { header: headers });
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Create a workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Save the workbook as a file
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export default jsonToExcel;

import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Skeleton, Popover, Button } from 'antd';
import getCurrencySymbol from '../Utils/currencySymbol';
import { formatDate } from '../Utils/date';

export default function ViewInvoice() {
  const axiosPrivate = useAxiosPrivate();
  const [invoices, setinvoices] = useState(null);
  const [error, seterror] = useState(null);
  const [ispending, setispending] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInvoices = async () => {
      try {
        const response = await axiosPrivate.get('/api/customer/invoice', {
          signal: controller.signal,
        });

        isMounted && setinvoices(response.data);
        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getInvoices();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  const handleDownload = (id) => {
    setLoading(true)
    let isMounted = true;
    const controller = new AbortController();
    const downloadInvoice = async () => {
      try {
        const response = await axiosPrivate.get(`/api/customer/invoice/${id}`, {
          signal: controller.signal,
          responseType: 'arraybuffer',
        });

        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(pdfBlob);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'PaidInvoice.pdf');
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        setLoading(false)
      } catch (err) {
        seterror(err);
        setispending(false);
        setLoading(false)
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    downloadInvoice();

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">View Paid Invoices</h3>
        {error && <h3 className="card-title">{error}</h3>}
        <div className="card-tools">
          <div className="input-group input-group-sm" style={{ width: 150 }}>
            <input
              type="text"
              name="table_search"
              className="form-control float-right"
              placeholder="Search"
            />
            <div className="input-group-append">
              <button type="submit" className="btn btn-default">
                <i className="fas fa-search" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-body table-responsive p-0"
        style={{ fontSize: '0.9em' }}
      >
        {' '}
        <Skeleton active loading={ispending} size={'large'}></Skeleton>
        {invoices && (
          <table className="table table-head-fixed text-wrap font-weight-bold">
            <thead>
              <tr>
                <th>Sale ID #</th>
                <th>Payment Source</th>
                <th>Currency</th>
                <th>Amount Paid</th>
                <th>Payment Date</th>
                <th>Orders Details</th>
                <th>Download Invoice</th>
              </tr>
            </thead>
            <tbody>
              {invoices &&
                invoices.map((invoice) => (
                  <tr>
                    <td>{invoice.transactionID}</td>
                    <td className="text-capitalize">{invoice.paymentSource}</td>
                    <td>{invoice.currency}</td>
                    <td className='text-success'>
                      {getCurrencySymbol(invoice.currency)}{' '}
                      {invoice.amount_paid}
                  
                    </td>
                    <td>{formatDate(invoice.payment_date)}</td>
                    <td>
                      {invoice.Orders.map((order) => (
                        <>
                          <Popover
                            content={
                              <div>
                                <p>Design Name : {order.design_name}</p>
                                <p>Order Type : {order.order_type}</p>
                                <p>
                                  Price : {getCurrencySymbol(invoice.currency)}{' '}
                                  {order.price}
                                </p>
                              </div>
                            }
                            title="Order Details"
                          >
                            <Button type="primary">
                              #{order?.serialNumber}
                            </Button>
                          </Popover>
                          <br /> <br />
                        </>
                      ))}
                    </td>
                   
                    <td>
                      <Button
                        type="primary"
                        loading={loading}
                        onClick={() => {
                          handleDownload(invoice.id);
                        }}
                      >
                        Download PDF
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

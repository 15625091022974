export default function getCurrencySymbol(currencyCode) {
    const currencySymbols = {
      'USD': '$',
      'CAD': 'C$',
      'GBP': '£',
      'EUR': '€',
      'AUD': 'A$',
      'JPY': '¥',
      // Add more currencies and symbols as needed
    };
  
    // Look up the symbol in the currencySymbols object
    const symbol = currencySymbols[currencyCode];
  
    // Return the symbol if found, or null if the currency code is not recognized
    return symbol || null;
  }
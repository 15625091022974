import React, { useState } from 'react';

export default function HeightWidth({heightP,widthP}) {

    const [height, setheight] = useState(0)
    const [width, setwidth] = useState(0)


    const Converter = function(data){

        let cm = (data*2.54).toFixed(2)
        let mm = (data*25.4).toFixed(2)
        

        return {mm,cm}

    }

  return (
    <>
      <div className="form-group">
        <label for="">Height</label>
        <input
          className="form-control"
          type="number"
          placeholder="Enter Height"
          onChange={(e)=>{setheight(e.target.value)}}
        />
        <label for="" className="d-block">IN:{height}</label>
        <label for="" className="d-block">CM:{Converter(height).cm}</label>
        <label for="" className="d-block">MM:{Converter(height).mm}</label>
      </div>
      <div className="form-group">
        <label for="">Width</label>
        <input
          className="form-control"
          type="number"
          placeholder="Enter Width"
          onChange={(e)=>{setwidth(e.target.value)}}
        />
        <label for="" className="d-block">IN:{width}</label>
        <label for="" className="d-block">CM:{Converter(width).cm}</label>
        <label for="" className="d-block">MM:{Converter(width).mm}</label>
      </div>
    </>
  );
}

import React from 'react'
import html2pdf from 'html2pdf.js';
import logo from './logo.png'
export default function InvoicePDF ({ orders, total, invoiceNo, InvoiceDate }) {

    const generatePDF = () => {
        const element = document.getElementById('invoice');
        const opt = {
            margin: [0.5, 0.5, 1, 0.5], // [top, left, bottom, right] in inches
            filename: 'myfile.pdf',
            image: { type: 'jpeg', quality: 0.99 },
            html2canvas: {
                scale: 2,
                logging: true, // Enable logging to debug style issues
                useCORS: true, // Use CORS to load external resources like Bootstrap
            },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
            pagebreak: { mode: ['css'] } // Enable CSS-based page breaks


        };
        html2pdf().set(opt).from(element).save('invoice.pdf');
    };

    return (
        <>
          <button className='btn btn-primary my-2 w-100' onClick={generatePDF}>Download PDF</button>
            <div id='invoice'>
                <div className='container'>
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <img src={logo} className='w-100' alt="Logo" />
                        </div>
                    </div>
                    <div className='row my-5'>
                        <div className="col-md-6 border border-dark">
                            <h5 style={{ color: 'green', textDecoration: 'underline' }}>Paid Invoice</h5>
                            <p className='fw-bolder'>
                                Payment
                                <br />
                                Method  <span>Bank Transfer</span>
                                <br />
                                Paid Date <span>{InvoiceDate}</span>
                                <br />
                                Invoice # <span>{invoiceNo}</span>
                            </p>
                        </div>
                        <div className="col-md-6 border border-dark">
                            <p className='fw-bolder'>
                                Invoice To
                                <br />
                                Abdul Rehman
                                <br />AA CREATIVE EMB
                                <br />arqazi@aacreativeemb.com
                            </p>
                        </div>
                    </div>
                    <div className='row'>
                        <table className='table table-bordered border border-dark'>
                            <thead>
                                <tr>
                                    <th>S-No</th>
                                    <th>Order#</th>
                                    <th>Date</th>
                                    <th>Design Name/PO</th>
                                    <th>Order Type</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody >
                                {orders.map(order => (
                                    <tr key={order.id} className='border border-dark' style={{fontSize:'10px','break-inside': 'avoid'}}>
                                        <td>{order.id}</td>
                                        <td>{order.orderNumber}</td>
                                        <td>{order.date}</td>
                                        <td>{order.designName}</td>
                                        <td>{order.orderType}</td>
                                        <td>{order.price}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={5}><h4>Total</h4></td>
                                    <td colSpan={1}><h4>$ {total}</h4></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>


                </div>
            </div>

          
        </>
    );
}

import React, { useState } from 'react'
import { Button, DatePicker, Spin } from 'antd'
import { DownloadOutlined } from '@ant-design/icons';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import jsonToExcel from '../Utils/excelData';

const { RangePicker } = DatePicker;


export default function Reports() {



    const [invoice, setInvoice] = useState({})
    const [date, setdate] = useState("");
    const [size, setSize] = useState('large');
    const [paidTotal, setPaidTotal] = useState("")
    const [unpaidTotal, setUnpaidTotal] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, seterror] = useState(null);
    const axiosPrivate = useAxiosPrivate();
    const handleExcel = async () => {

        if (!date) {

            return seterror(`please select date`)

        }

        seterror("")

        const { data: OrdersData } = await axiosPrivate.get('/api/admin/report/orderByDate', {
            // signal: controller.signal,
            params: { date },
        });

        // console.log(OrdersData)

        let ordersPrint = OrdersData.map(({
            serialNumber,
            design_name,
            order_type,
            order_priority,
            order_status,
            payment_status,
            order_category,
            price, Customer }) => ({ serialNumber, design_name, order_type, order_priority, order_status, payment_status, order_category, price, ...Customer }))


        console.log(ordersPrint)

        jsonToExcel(ordersPrint, 'orders')



    }

    const downloadCustomers = async () => {
        const { data: CustomerData } = await axiosPrivate.get('/api/admin/customer', {
            //   signal: controller.signal,
        });

        let customerArray = CustomerData.map(({
            customer_name,
            currencyType,
            referral,
            phone,
            fax,
            address,
            city,
            state, User }) => ({
                customer_name,
                currencyType,
                referral,
                phone,
                fax,
                address,
                city,
                state, ...User
            }))
        // console.log(ordersPrint)

        jsonToExcel(customerArray, 'cutomers')
    }
    const downloadDuePayments = async () => {
        const { data: DuePaymentOrdersData } = await axiosPrivate.get('/api/admin/report/duePayments', {
            //   signal: controller.signal,
        });

        let DuePaymentsOrderArray = DuePaymentOrdersData.map(({
            design_name, order_type, order_priority, order_status, payment_status, order_category, price, serialNumber, Customer }) => ({
                design_name, order_type, order_priority, order_status, payment_status, order_category, price, serialNumber, ...Customer
            }))


        // // console.log(ordersPrint)

        jsonToExcel(DuePaymentsOrderArray, 'duePayments')



    }
    const PaymentInSelectedMonth = async (date) => {
        setPaidTotal("")
        if (date) {
            setLoading(true)
            const response = await axiosPrivate.get('/api/admin/report/payByMonth', {
                params: { date }
            });
            setLoading(false)
            if (response.data.length) {
                setPaidTotal(response.data)

            }
        }
    }
    const UnPaidOrderInSelectedMonth = async (date) => {
        setUnpaidTotal("")
        if (date) {
            setLoading(true)
            const response = await axiosPrivate.get('/api/admin/report/unpaidByMonth', {
                params: { date }
            });
            setLoading(false)
            if (response.data.length) {
                setUnpaidTotal(response.data)

            }
        }
    }


    return (
        <>
            <div className="container">
                <div className="row">

                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-dark">
                                <h5 className="text-center text-light">
                                    Customers Report
                                </h5>
                            </div>
                            <div className="card-body">
                                <Button onClick={downloadCustomers} type="primary" block shape="round" icon={<DownloadOutlined />} size={size}>
                                    Download Cutomers
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-dark">
                                <h5 className="text-center text-light">
                                    Due Payments Report
                                </h5>
                            </div>
                            <div className="card-body">
                                <Button onClick={downloadDuePayments} type="primary" block shape="round" icon={<DownloadOutlined />} size={size}>
                                    Download Due Payments
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-dark">
                                <h5 className="text-center text-light">
                                    Total Paid Invoices (By Month)
                                </h5>
                            </div>

                            <Spin tip="Loading" size="small" spinning={loading}>
                                <div className="card-body">
                                    <label htmlFor="" className='form-select mx-2'>Select Month</label>
                                    <DatePicker onChange={(a, b) => { PaymentInSelectedMonth(b) }} picker="month" />
                                    <hr />
                                    {
                                        paidTotal
                                        &&
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">TOTAL INVOICES</th>
                                                    <th scope="col">AMOUNT</th>
                                                    <th scope="col">CURRENCY</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paidTotal.map(({ totalInvoices, totalAmount, currency }) => (

                                                    <tr>
                                                        <td>{totalInvoices}</td>
                                                        <td>{totalAmount}</td>
                                                        <td>{currency}</td>
                                                    </tr>

                                                ))}
                                            </tbody>
                                        </table>
                                    }

                                </div>
                            </Spin>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-dark">
                                <h5 className="text-center text-light">
                                    Total Unpaid Orders (By Month)
                                </h5>
                            </div>

                            <Spin tip="Loading" size="small" spinning={loading}>
                                <div className="card-body">
                                    <label htmlFor="" className='form-select mx-2'>Select Month</label>
                                    <DatePicker onChange={(a, b) => { UnPaidOrderInSelectedMonth(b) }} picker="month" />
                                    <hr />
                                    {
                                        unpaidTotal
                                        &&
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">TOTAL ORDERS</th>
                                                    <th scope="col">AMOUNT</th>
                                                    <th scope="col">CURRENCY</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {unpaidTotal.map(({ totalOrders, totalAmount, currencyType }) => (

                                                    <tr>
                                                        <td>{totalOrders}</td>
                                                        <td>{totalAmount}</td>
                                                        <td>{currencyType}</td>
                                                    </tr>

                                                ))}
                                            </tbody>
                                        </table>
                                    }

                                </div>
                            </Spin>

                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header bg-dark">
                                <h5 className="text-center text-light">
                                    Orders Report
                                </h5>
                            </div>
                            <div className="card-body">
                                <label htmlFor="" className='form-select mx-2'>Select Range</label>
                                <RangePicker onChange={(dates, datestr) => { setdate({ start: datestr[0], end: datestr[1] }) }} />
                                <hr />
                                {/* <button onClick={() => { handleExcel() }} className='btn mx-2 btn-sm btn-primary' >Download Excel</button> */}
                                <Button onClick={handleExcel} type="primary" block shape="round" icon={<DownloadOutlined />} size={size}>
                                    Download Orders
                                </Button>
                                {error && <h4 className="bg-warning text-dark text-center rounded rounded-pill my-2">{error}</h4>}
                            </div>
                        </div>
                    </div>
        
                </div>
            </div>
        </>
    )
}

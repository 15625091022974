import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import FilePreview from '../Utils/FilePreview ';
import { Skeleton, Popover, Button, Input } from 'antd';
import getCurrencySymbol from '../Utils/currencySymbol';


export default function ViewOrder() {
  const { Search } = Input;
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [orders, setorders] = useState(null);
  const [error, seterror] = useState(null);
  const [ispending, setispending] = useState(true);

  // search
  // --------------------------------------------
  const handleSearch = (searchText) => {
    setispending(true)
    let isMounted = true;
    const controller = new AbortController();

    const getSearchOrders = async () => {
      try {
        setispending(true);
        setorders("")
        seterror(false);
        const response = await axiosPrivate.get('/api/customer/order/search', {
          signal: controller.signal,
          params: { searchText },
        });
        if (response.data.length) {

          isMounted && setorders(response.data);
          seterror("")
        }
        else {

          seterror(`No Search Result Found`);
        }

        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
      }
    };

    getSearchOrders();

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  // --------------------------------------------
  // search 


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getOrders = async () => {
      seterror(false);
      try {
        const response = await axiosPrivate.get('/api/customer/order', {
          signal: controller.signal,
        });

        isMounted && setorders(response.data);
        setispending(false);
      
      } catch (err) {
        seterror(err);
        setispending(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getOrders();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);



  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">All Orders</h3>

        <div className="card-tools">
          <div className="input-group input-group-sm" style={{ width: 150 }}>
            <Search placeholder="input search text" onSearch={value => handleSearch(value)} enterButton />
          </div>
        </div>
      </div>
      <div
        className="card-body table-responsive p-0"
        style={{ fontSize: '0.7em' }}
      >
        {' '}
        <Skeleton active loading={ispending} size={'large'}></Skeleton>
        {error && <h3 className="bg-danger rounded rounded-pill my-5 text-center">{error}</h3>}
        {orders && (
          <table className="table table-head-fixed text-wrap font-weight-bold">

            <thead>
              <tr>
                <th>S.no #</th>
                <th>Design name</th>
                <th>Order Type</th>
                <th>Status</th>
                <th>Payment Status</th>
                <th>Price</th>
                <th>Design Placement</th>
                <th>Files</th>
                <th>Color Preference</th>
                <th>Garment Material</th>
                <th>Dimension</th>
                <th>Instruction</th>
                <th>Order Priority</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders &&
                orders.map((order) => (
                  <tr>
                    <td>{order?.serialNumber}</td>
                    <td>{order?.design_name}</td>
                    <td>{order.order_type}</td>
                    <td>
                      {order?.order_status == 'On Hold' && (
                        <span class="badge bg-lime">{order?.order_status}</span>
                      )}
                      {order?.order_status == 'Cancel' && (
                        <span class="badge bg-gray">{order?.order_status}</span>
                      )}
                      {order?.order_status == 'Released' && (
                        <span class="badge bg-success">
                          {order?.order_status}
                        </span>
                      )}
                      {order?.order_status == 'In Progress' && (
                        <span class="badge bg-warning">
                          {order?.order_status}
                        </span>
                      )}
                    </td>
                    <td>{order?.payment_status}</td>
                    <td>
                      <span className="input-group-text fw-b bg-primary">
                        {getCurrencySymbol(order.Customer.currencyType)}{' '}
                        {order?.price}
                      </span>
                    </td>

                    <td>{order?.design_placement.replace(/[\[\]"]/g, '')}</td>
                    {/* <td>
                      <small className="d-block fw-b">Customer Files</small>
                      {order.orderFiles.map((file, index) => (
                        <FilePreview
                          key={index}
                          fileName={file.filename}
                          fileUrl={file.filename}
                        ></FilePreview>
                      ))}
                      <small className="d-block fw-b">Released Files</small>
                      {order.releasedFiles.map((file, index) => (
                        <FilePreview
                          key={index}
                          fileName={file.filename}
                          fileUrl={file.filename}
                        ></FilePreview>
                      ))}
                      </td>*/}
                    <td>
                      <Popover
                        content={
                          <div className="container">
                            <div className="row">
                              <div className="col-6 border">
                                <p class="">Customer Files</p>
                                <hr />

                                {order.orderFiles.map((file, index) => (
                                  <FilePreview
                                    key={index}
                                    fileName={file.filename}
                                    fileUrl={file.filename}
                                  ></FilePreview>
                                ))}
                              </div>
                              <div className="col-6 border">
                                <p class="">Released Files</p>
                                <hr />

                                {order.releasedFiles.map((file, index) => (
                                  <FilePreview
                                    key={index}
                                    fileName={file.filename}
                                    fileUrl={file.filename}
                                  ></FilePreview>
                                ))}
                              </div>
                            </div>
                          </div>
                        }
                        title="---------------------All Files---------------------"
                      >
                        <Button type="primary">View Files</Button>
                      </Popover>
                    </td>
                    <td>{order?.color_preference}</td>
                    <td>{order?.garment_material}</td>
                    <td>{order?.dimension}</td>
                    <td>{order?.instruction}</td>
                    <td>{order?.order_priority}</td>
                    {/* <td>{order?.email_send_copy}</td>*/}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import FormInput from '../Utils/FormInput';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function AddDigitizer() {
  const location = useLocation();
  let navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [contractDetails, setcontractDetails] = useState('N/A');
  const [employeeType, setemployeeType] = useState('Digitizer');
  const [errMsg, setErrMsg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
    username: '',
    salary: '',
    commissionType: '',
    commission: '',
    contractDetails: '',
    email: '',
    smsNumber: '',
    dateOfJoining: '',
  });
  const inputs = [
    {
      id: 1,
      name: 'email',
      type: 'email',
      placeholder: 'Email',
      errorMessage: 'It should be a valid email address!',
      label: 'Email',
      required: true,
    },
    {
      id: 2,
      name: 'password',
      type: 'password',
      placeholder: 'Password',
      errorMessage:
        'Password should be 8-15 characters',
      label: 'Password',
      pattern: `^[A-Za-z0-9 ]{8,15}$`,
      required: true,
    },
    {
      id: 3,
      name: 'username',
      type: 'text',
      placeholder: 'Enter Username',
      errorMessage:
        "Username should be 8-15 characters and shouldn't include any special character!",
      label: 'Username',
      pattern: '^[A-Za-z0-9 ]{3,15}$',
      required: true,
    },
    {
      id: 4,
      name: 'salary',
      type: 'number',
      placeholder: 'Enter Salary',
      errorMessage: 'salary should be in numbers',
      label: 'Salary',
      pattern: '^[0-9]{1,8}$',
      required: false,
      defaultValue:0
    },
    {
      id: 5,
      name: 'commissionType',
      type: 'text',
      placeholder: 'Enter Salary Currency',
      errorMessage: 'salary currency contains alphabets',
      label: 'Salary Currency',
      pattern: '^[A-Za-z]{1,8}$',
      required: false,
      value: 'PKR',
    },
    {
      id: 6,
      name: 'commission',
      type: 'number',
      placeholder: 'Enter Commision',
      errorMessage: 'commision contains numbers',
      label: 'Commission',
      pattern: '^[0-9]{1,8}$',
      required: false,
      defaultValue: 0
    },
    {
      id: 7,
      name: 'smsNumber',
      type: 'text',
      placeholder: 'Enter SMS Number',
      errorMessage: 'SMS Number contains numbers',
      label: 'SMS Number',
      pattern: '^[0-9]{1,15}$',
      required: false,
    },
    {
      id: 8,
      name: 'dateOfJoining',
      type: 'date',
      placeholder: 'Enter Date Of Joining',
      errorMessage: 'Date Of Joining ',
      label: 'Date Of Joining',
      pattern: '^[0-9]{1,15}$',
      required: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let isMounted = true;
    const controller = new AbortController();
    const createDigitizer = async () => {
  
      let data = { ...values, contractDetails ,employeeType };
      try {
        const response = await axiosPrivate.post('/api/admin/employee', data, {
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal,
        });
        
        setErrMsg("")
        isMounted && navigate('/admin/viewEmployees');
      } catch (error) {
        setIsSubmitting(false);

        setErrMsg(error.response.data.error)
      }
    };

    createDigitizer();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="container">
      <div className="row my-4 ">
        <div className="col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Add Employee</h3>
            </div>
            <div className="card-body w-100">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="form-group">
                  <label for="">Select Type</label>
                  <select
                    class="form-control"
                    onChange={(e) => {
                      setemployeeType(e.target.value);
                    }}
                  >
                    <option value="Digitizer" selected>
                      Digitizer
                    </option>
                    <option value="Vectorizer">
                    Vectorizer
                    </option>
                    
                  </select>
                </div>
                {inputs.map((input) => (
                  <FormInput
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                ))}
                <div className="text-center form-group">
                  <h3 className="my-2">Contract Details</h3>

                  <textarea
                    class="form-control"
                    rows="3"
                    placeholder="Enter Contract Details"
                    onChange={(e) => {
                      setcontractDetails(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="text-center">
                  <input
                    type="submit"
                    className="btn btn-success my-3 w-50"
                    value={'Create Employee'}
                    disabled={isSubmitting}
                  />
                </div>
              </form>
              {errMsg &&<div className='bg-danger text-center rounded rounded-pill'>{errMsg}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

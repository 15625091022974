import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';

export default function Navbar() {
  const logout = useLogout();
  const navigate = useNavigate();
     const signOut = async () => {
         await logout();
         navigate('/login');
     }
  return (
    <nav className="main-header navbar navbar-expand navbar-dark navbar-dark">
      {/* Left navbar links */}
      <ul className="navbar-nav ml-auto">
        <li className="nav-item  d-sm-inline-block">
          <Link to={'/customer'} className="nav-link">
            Home
          </Link>
        </li>
        <li className="nav-item d-sm-inline-block ">
          <a onClick={() => signOut()} className="nav-link">
            Logout
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <i className="fas fa-bars" />
          </a>
        </li>
      </ul>
      {/* Right navbar links */}
    </nav>
  );
}

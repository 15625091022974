import React, { useState } from 'react';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
export default function Reset() {
  const {token} =useParams()
  const navigate =useNavigate()

  const [errMsg, setErrMsg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setpassword] = useState(false);
  const [confirmPassword, setconfirmPassword] = useState(false);
  let handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(
        '/api/user/update',
        { token ,password },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );

      navigate('/login');
      setIsSubmitting(false);
      setErrMsg('');
    } catch (error) {
 
      setErrMsg(error.response.data.error);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-log">
      <div className="container-fluid">
        <div className="row justify-content-center" style={{ height: '100vh' }}>
          <div className="col-12 col-md-5 align-self-center">
            <h1 className="text-center gra-text">
              AA Creative EMB <br /> Customer Portal
            </h1>
            <div className="card card-success bg-card-ini text-white ">
              <div className="card-body ">
               
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                 
                  <div className="formInput">
                    <label className="form-label">New Password</label>
                    <Input.Password
                      size="large"
                      placeholder="Enter New Password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      onChange={(e) => {
                        setpassword(e.target.value);
                      }}
                    />
                    
                  </div>
                 
                  <div className="text-center my-4">
                    <button
                      className="btn btn-primary btn-log w-50"
                      disabled={isSubmitting}
                    >
                      Reset Password
                    </button>
                  </div>
                  
                </form>
                {errMsg && (
                  <div className="bg-danger text-center rounded rounded-pill">
                    {errMsg}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

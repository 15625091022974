import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import { Skeleton, Button, Input } from 'antd';
import {
  EditFilled,PlusOutlined 

} from '@ant-design/icons';
import getCurrencySymbol from '../Utils/currencySymbol';
const { Search } = Input;
export default function ViewCustomers() {

  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [resetLink, setresetLink] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [error, seterror] = useState(null);
  const [ispending, setispending] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {

    let isMounted = true;
    const controller = new AbortController();

    const getCustomers = async () => {

      try {


        const response = await axiosPrivate.get('/api/admin/customer', {
          signal: controller.signal,
        });


        isMounted && setCustomers(response.data);
        setispending(false);
      } catch (err) {

        seterror(err);
        setispending(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getCustomers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleReset = (email) => {
    setLoading(true)
    let isMounted = true;
    const controller = new AbortController();

    const getResetPasswordLink = async () => {

      try {


        const response = await axiosPrivate.get(`/api/admin/customer/resetpassword/${email}`, {
          signal: controller.signal,
        });


        isMounted && setresetLink(response.data);
        if (response.data) {
          window.open(response.data, '_blank');
        }
        setispending(false);
        setLoading(false)
      } catch (err) {

        seterror(err);
        setispending(false);
        setLoading(false)
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getResetPasswordLink();

    return () => {
      isMounted = false;
      controller.abort();
    };

  }
  const handleSearch = (searchText) => {
    setispending(true)
    let isMounted = true;
    const controller = new AbortController();

    const getSearchCustomers = async () => {
      try {
        setispending(true);

        const response = await axiosPrivate.get('/api/admin/customer/search', {
          signal: controller.signal,
          params: { searchText },
        });
        if(response.data.length){

          isMounted && setCustomers(response.data);
          seterror("")
        }
        else{
          setCustomers("")
          seterror(`No Result Found`);
        }



        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
      }
    };

    getSearchCustomers();

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">View Customer</h3>
      
        <div className="card-tools">
          <div className="input-group input-group-sm" style={{ width: 150 }}>

            <Search placeholder="input search text" onSearch={value => handleSearch(value)} enterButton />
          </div>
        </div>
      </div>
      <div
        className="card-body table-responsive p-0"
        style={{ height: '80vh' }}
      >
          {error && <h3 className="card-title">{error}</h3>}
        <Skeleton active loading={ispending} size={'large'} ></Skeleton>
        {customers && <table className="table table-head-fixed text-wrap">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Email</th>
              <th>Currency</th>
              <th>Phone</th>
              <th>Reset Password</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              customers.map((customer) => (
                <tr>
                  <td>{customer?.customer_name}</td>
                  <td>{customer?.User.email}</td>
                  <td>{getCurrencySymbol(customer?.currencyType)} {customer?.currencyType}</td>
                  <td>{customer.phone}</td>
                  <td>
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={() => {
                        handleReset(customer?.User.email);
                      }}
                    >
                      Get Password Reset Link
                    </Button>
                  </td>
                  <td>
                    <div className="icon-container m-0">
                      <td>
                        
                        <Link
                          className="btn bg-light p-0"
                          to={`/admin/editCustomer/${customer.id}`}
                        >
                          <EditFilled
                            style={{ fontSize: '1.2rem', color: '#023047' }}
                          />
                        </Link>
                      </td>
                      <span
                        className="text bg-warning"
                        style={{ zIndex: '9' }}
                      >
                        Edit Customer
                      </span>
                    </div>
                  
                    <div className="icon-container m-0">
                      <td>
                        
                        <Link
                          className="btn bg-light p-0"
                          to={`/admin/orders/new/${customer.id}`}
                        >
                         <PlusOutlined
                            style={{ fontSize: '1.2rem', color: '#023047' }}
                          />
                        </Link>
                      </td>
                      <span
                        className="text bg-warning"
                        style={{ zIndex: '9' }}
                      >
                        Create Customer Order
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>}
      </div>
    </div>
  )
}

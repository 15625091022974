// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .formInput{
    display: flex;
    flex-direction: column;
    width: 280px;
} */

/* input{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid gray;
} */


/* label{
    font-size: 12px;
    color: gray;
} */

.valid-error{
    font-size: 12px;
    font-weight: 600;
    color: whitesmoke;
    display: none;

}

input:invalid[focused="true"]{
    border: 2px solid #dc3545;
}

input:invalid[focused="true"] ~ .valid-error{
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/Utils/formInput.css"],"names":[],"mappings":"AAAA;;;;GAIG;;AAEH;;;;;GAKG;;;AAGH;;;GAGG;;AAEH;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;;AAEjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* .formInput{\n    display: flex;\n    flex-direction: column;\n    width: 280px;\n} */\n\n/* input{\n    padding: 15px;\n    margin: 10px 0px;\n    border-radius: 5px;\n    border: 1px solid gray;\n} */\n\n\n/* label{\n    font-size: 12px;\n    color: gray;\n} */\n\n.valid-error{\n    font-size: 12px;\n    font-weight: 600;\n    color: whitesmoke;\n    display: none;\n\n}\n\ninput:invalid[focused=\"true\"]{\n    border: 2px solid #dc3545;\n}\n\ninput:invalid[focused=\"true\"] ~ .valid-error{\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react'
import InvoicePDF from './InvoicePDF';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
export default function CustomInvoice() {
    const axiosPrivate = useAxiosPrivate();


    const [isSubmitting, setIsSubmitting] = useState(false)
    const [err, setErrMsg] = useState('')
    const [data, setData] = useState({
        invoiceNo:'gdg-200x-12w1',
        totalOrders:10,
        date:Date.now(),
        month:1,
        totalAmount:200,
        currency:'GBP'
    })
    const [invoiceNo, setInvoiceNo] = useState('');
    const [payDate, setPayDate] = useState('');
    const [totalOrders, setTotalOrders] = useState(10);
    const [invoiceMonth, setInvoiceMonth] = useState('2024-08');
    const [totalAmount, setTotalAmount] = useState(2500);
    const [currency, setCurrency] = useState('$');
    const [orders, setOrders] = useState('');

    const designNames = [
        'Horse Love', 'Alice Logo', 'Sunset Boulevard', 'Cherry Blossom', 'Ocean Breeze', 'Art Logo'
    ];

    const orderTypes = ['Digitizing Order', 'Vector Order'];

    const generateOrders = () => {
        const orderList = [];
        const amountPerOrder = totalAmount / totalOrders;
        for (let i = 0; i < totalOrders; i++) {
            const date = new Date(`${invoiceMonth}-01`);
            date.setDate(Math.floor(Math.random() * 30) + 1);
            orderList.push({
                id: i + 1,
                orderNumber: `ORD${Math.floor(1000 + Math.random() * 9000)}`,
                date: date.toISOString().split('T')[0],
                designName: designNames[i % designNames.length],
                orderType: orderTypes[Math.floor(Math.random() * orderTypes.length)],
                price: `${currency}${amountPerOrder.toFixed(2)}`
            });
        }
        setOrders(orderList);
    };


    const getPDF = async (e) => {

        e.preventDefault()
        try {
            setIsSubmitting(true)
            const response = await axiosPrivate.post('/api/admin/report/dummyInvoice', data, {
                headers: { 'Content-Type': 'application/json' },
                //   signal: controller.signal,
                responseType: 'arraybuffer',
            });
            console.log(response)
            // isMounted && setUsers(response.data);
            // isMounted && navigate('/admin/orders/pending');

            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

            // Create a temporary URL for the blob
            const url = window.URL.createObjectURL(pdfBlob);

            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'DueInvoice.pdf');
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setErrMsg("")
            setIsSubmitting(false)
        } catch (error) {
            setErrMsg(error.response.data)
            setIsSubmitting(false);
            // navigate('/login', { state: { from: location }, replace: true });
        }

    }



    return (
        <div className='container'>
            <h1>Dummy Invoice</h1>

            <form onSubmit={(e) => { getPDF(e) }} >
                <div>
                    <label className='form-label'>Invoice No: </label>
                    <input className='form-control' type="text" defaultValue={data.invoiceNo} onChange={(e) => setData({ ...data, invoiceNo: e.target.value })} />
                </div>
                <div>
                    <label className='form-label'>Total Orders: </label>
                    <input className='form-control' type="number" defaultValue={data.totalOrders} onChange={(e) => setData({ ...data, totalOrders: e.target.value })} />
                </div>
                <div>
                    <label className='form-label'>Payment Date: </label>
                    <input className='form-control' defaultValue={(new Date).toISOString().split('T')[0]} type="date" onChange={(e) => setData({ ...data, date: e.target.value })} />
                </div>
                <div>
                    <label className='form-label'>Month of Invoice: </label>
                    <select className='form-control' defaultValue={data.month} onChange={(e) => setData({ ...data, month: e.target.value })} >
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>
                <div>
                    <label className='form-label'>Total Amount: </label>
                    <input className='form-control' type="number" defaultValue={data.totalAmount} onChange={(e) => setData({ ...data, totalAmount: e.target.value })} />
                </div>
                <div>
                    <label className='form-label'>Currency: </label>
                    <select className="form-control" onChange={(e) => setData({ ...data, currency: e.target.value })} >
                        <option selected  value="GBP">GBP</option>
                        <option value="USD">US</option>
                    </select>

                </div>

                {!isSubmitting && <input className='btn btn-success w-100 my-2' type="submit" value="Download PDF" />}
                {isSubmitting && <input className='btn btn-disabled w-100 my-2' value="Downloading PDF..." disabled />}
            </form>



            

        </div>

    )
}

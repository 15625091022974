import React from 'react';

export default function Unauthorized() {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center align-content-center bg-warning" style={{height:"100vh"}}>
        <div className="col-12 col-md-10 text-center bg-white rounded rounded-pill">
            <h1 className="display-4 text-warning">403</h1>

            <h1 className='display-3'>
              <i className="fas fa-exclamation-triangle text-warning " /> ACCESS
              NOT GRANTED
            </h1>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import getCurrencySymbol from '../Utils/currencySymbol';
import { formatDate } from '../Utils/date';
import useAuth from '../../hooks/useAuth';
import { Skeleton } from 'antd';
export default function ViewPayment() {
  const axiosPrivate = useAxiosPrivate();
  const [noInvoice, setnoInvoice] = useState(false);
  const [orders, setorders] = useState('');
  const [approvalURL, setapprovalURL] = useState('');
  const [total, settotal] = useState('');
  const [currencyType, setcurrencyType] = useState('');
  const [error, seterror] = useState('');
  const [ispending, setispending] = useState(true);
  const { auth } = useAuth();
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getInvoice = async () => {
      try {
        const response = await axiosPrivate.get(
          '/api/customer/invoice/create',
          {
            signal: controller.signal,
          },
        );
        if (response.data.message == 'No Due Invoice') {
          setnoInvoice(true);
          setispending(false);
        } else {
          setorders(response?.data?.orders);
          setapprovalURL(response?.data?.approvalUrl);
          settotal(response?.data?.totalPrice);
          setcurrencyType(response?.data?.currencyType);
          setispending(false);
        }
      } catch (err) {
        seterror(err);
        setispending(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getInvoice();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
   
      <Skeleton active loading={ispending} size={'large'}></Skeleton>
      {noInvoice && <h1 className='bg-success rounded-pill p-3 text-center'>Thank you for your timely payment, we appreciate your cooperation.</h1>}
      {orders && (
        <div className="invoice p-3 mb-3">
          <div className="row">
            <div className="col-12">
              <h4>
                <i className="fas fa-globe" /> AAcreative EMB, Inc.
                <small className="float-right">
                  Date: {formatDate(Date.now())}
                </small>
              </h4>
            </div>
          </div>
          <div className="row invoice-info">
            
            <div className="col-sm-4 invoice-col">
              To
              <address>
                <strong className="text-uppercase">{auth.username}</strong>
                <br />
               
              </address>
            </div>
           
          </div>
          <div className="row">
            <div className="col-12 table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>S #</th>
                    <th>Order #</th>
                    <th>Order Placement Date</th>
                    <th>Design Name</th>
                    <th>Order Type</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{order.serialNumber}</td>
                      <td>{formatDate(order.createdAt)}</td>
                      <td>{order.design_name}</td>
                      <td>{order.order_type}</td>
                      <td>
                        {getCurrencySymbol(currencyType)} {order.price}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th style={{ width: '50%' }}>Subtotal:</th>
                      <td>
                        {' '}
                        {getCurrencySymbol(currencyType)}{' '}
                        {total}
                      </td>
                    </tr>
                    <tr>
                      <th>Total:</th>
                      <td>
                        {getCurrencySymbol(currencyType)}{' '}
                        {total}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row justify-content-end no-print">
            <div className="col-md-6">
              {/*<PayPalButton details={{amount:data.totalPrice,orderIds,currencyType:data.currencyType}}></PayPalButton>*/}

              <a
                href={approvalURL}
                target="_blank" 
                rel="noopener noreferrer"
                className="btn btn-primary w-100"
              >
                Pay With PayPal
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { MultiSelect } from 'react-multi-select-component';
import getCurrencySymbol from '../Utils/currencySymbol';

export default function AddInvoice() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [customers, setcustomers] = useState(null);
  const [currency, setcurrency] = useState(null);
  const [orders, setorders] = useState('');
  const [customer_id, setcustomer_id] = useState(null);
  const [ispending, setispending] = useState(null);
  const [selected, setSelected] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [values, setvalues] = useState({ paymentSource: 'self' });

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCustomer = async () => {
      try {
        const response = await axiosPrivate.get(`/api/admin/customer`, {
          signal: controller.signal,
        });

        isMounted && setcustomers(response.data);
        setErrMsg('');
        setispending(false);
      } catch (error) {
        setErrMsg(error.response.data.error);
        setispending(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getCustomer();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleChange = async (customer_id) => {
    setcustomer_id(customer_id);
    const [customer] = customers.filter((cutomer) => cutomer.id == customer_id);
    setcurrency(customer.currencyType);

    let isMounted = true;
    const controller = new AbortController();

    try {
      const response = await axiosPrivate.get(
        `/api/admin/order/cusOrders/${customer_id}`,
        {
          signal: controller.signal,
        },
      );

      isMounted && setorders(response.data);

      setErrMsg('');
      setispending(false);
    } catch (error) {
      setErrMsg(error.response.data.error);
      setispending(false);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const amount_paid = selected.reduce(
      (prev, next) => Number(prev) + Number(next.price),
      0,
    );

    const orders_id = selected.map((item) => item.value);
    const data = { ...values, orders_id, customer_id, amount_paid };

    if (!data.hasOwnProperty('customer_id') || data.customer_id == null) {
      setErrMsg('Please Select Customer');
      setIsSubmitting(false);
    } else if (
      !data.hasOwnProperty('orders_id') ||
      data.orders_id.length == 0
    ) {
      setErrMsg('Please Select Orders');
      setIsSubmitting(false);
    } else if (!data.hasOwnProperty('amount_paid')) {
      setErrMsg('Please Type Amount ');
      setIsSubmitting(false);
    } else if (!data.hasOwnProperty('payment_date')) {
      setErrMsg('Please Select Payment Date');
      setIsSubmitting(false);
    } else if (!data.hasOwnProperty('paymentSource')) {
      setErrMsg('Please Select Payment Source');
      setIsSubmitting(false);
    } else if (!data.hasOwnProperty('transactionID')) {
      setErrMsg('Please Type Transaction ID ');
      setIsSubmitting(false);
    } else {
      let isMounted = true;
      const controller = new AbortController();

      const postInvoice = async () => {
        try {
          const response = await axiosPrivate.post(
            `/api/admin/invoice/create`,
            data,
            {
              headers: { 'Content-Type': 'application/json' },
              signal: controller.signal,
            },
          );
          isMounted && navigate('/admin/viewInvoices');
          setErrMsg('');
          setispending(false);
        } catch (error) {
          setErrMsg(error.response.data.error);
          setispending(false);
          setIsSubmitting(false);
          // navigate('/login', { state: { from: location }, replace: true });
        }
      };

      postInvoice();

      return () => {
        isMounted = false;
        controller.abort();
      };
    }
  };

  return (
    <div className="container">
      <div className="row my-4">
        <div className="col-12">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Add Invoice</h3>
            </div>
            <div className="card-body">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="form-group">
                  <label for="">Customers</label>
                  <select
                    class="form-control"
                    onChange={(e) => {
                      handleChange(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      Select Customers
                    </option>
                    {customers &&
                      customers.map((customer) => (
                        <option value={customer.id}>
                          {customer.customer_name}
                        </option>
                      ))}
                  </select>
                </div>
                {orders.length > 0 ? (
                  <>
                    <div className="form-group">
                      <label for="exampleInputEmail1">Select Orders</label>

                      <MultiSelect
                        options={orders.map((order) => ({
                          label: order.design_name,
                          value: order.id,
                          price: order.price,
                        }))}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                      />
                    </div>
                    {selected.length > 0 && (
                      <>
                        <div className="input-group form-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              {getCurrencySymbol(currency)}
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={selected.reduce(
                              (prev, next) => Number(prev) + Number(next.price),
                              0,
                            )}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">.00</span>
                          </div>
                        </div>
                        <div className="form-group">
                          <label for="">Date of Payment</label>
                          <input
                            className="form-control"
                            type="date"
                            placeholder="Enter Date of Payment"
                            onChange={(e) => {
                              setvalues({
                                ...values,
                                payment_date: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label for="">Payment Source</label>
                          <select
                            class="form-control"
                            onChange={(e) => {
                              e.target.value == 'paypal'
                                ? setInputVisible(true)
                                : setInputVisible(false);
                              setvalues({
                                ...values,
                                paymentSource: e.target.value,
                              });
                            }}
                          >
                            <option value="self" selected>
                              Self
                            </option>
                            <option value="paypal">Paypal</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label for="">Transaction ID</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Transaction Id"
                            onChange={(e) => {
                              setvalues({
                                ...values,
                                transactionID: e.target.value,
                              });
                            }}
                          />
                        </div>{' '}
                      </>
                    )}
                  </>
                ) : (
                  <h1 className="text-center bg-success">No Due Payments</h1>
                )}
                <div className="text-center my-4">
                  <button
                    className="btn btn-primary w-50"
                    disabled={isSubmitting}
                  >
                    Create Paid Invoice
                  </button>
                </div>
              </form>
              {errMsg && (
                <div className="bg-danger text-center rounded rounded-pill">
                  {errMsg}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import './App.css';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import CustomerDashLayout from './components/customer/CustomerDashLayout';
import Main from './components/customer/Main';
import ViewOrder from './components/customer/ViewOrder';
import CreateOrder from './components/customer/CreateOrder';
import Setting from './components/customer/Setting';
import AdminDashLayout from './components/admin/AdminDashLayout';
import RequireAuth from './components/RequireAuth';
import AdminViewPendingOrder from './components/admin/AdminViewPendingOrder';
import AdminCreateOrder from './components/admin/AdminCreateOrder';
import AdminViewOrder from './components/admin/AdminViewOrder';
import AddDigitizer from './components/admin/AddDigitizer';
import ViewDigitizers from './components/admin/ViewDigitizers';
import AssignOrder from './components/admin/AssignOrder';
import ViewCustomers from './components/admin/ViewCustomers';
import EditOrder from './components/admin/EditOrder';
import EditDigitizer from './components/admin/EditDigitizer';
import AddCustomer from './components/admin/AddCustomer';
import ViewAssigned from './components/admin/ViewAssigned';
import ReleaseOrder from './components/admin/ReleaseOrder';
import ReAssign from './components/admin/ReAssign';
import Reset from './components/Reset';
import ViewPayment from './components/customer/ViewPayment';
import PersistLogin from './components/Utils/PersistLogin';
import Unauthorized from './components/Unauthorized';
import ViewInvoice from './components/customer/ViewInvoice';
import ViewInvoices from './components/admin/ViewInvoices';
import DuePayments from './components/admin/DuePayments';
import AddInvoice from './components/admin/AddInvoice';
import DirectPayment from './components/DirectPayment';
import Success from './components/Utils/Success';
import Error from './components/Utils/Error';
import EditCustomer from './components/admin/EditCustomer';
import Reports from './components/admin/Reports';
import CustomInvoice from './components/admin/CustomInvoice';


export const ROLES = {
  Customer: 2001,
  Admin: 1984,
};

function App() {



  return (
    
    <>
      
      <Routes>
        <Route path={'/'} element={<Navigate to={'/login'}></Navigate>}></Route>
        <Route path={'/login'} element={<Login></Login>}></Route>
        <Route path={'/reset/:token'} element={<Reset></Reset>}></Route>
        <Route path={'/payment/:token'} element={<DirectPayment></DirectPayment>}></Route>
        <Route path={'/register'} element={<Register></Register>}></Route>
        <Route path={'/paymentSuccess'} element={<Success></Success>}></Route>
        <Route path={'/paymentError'} element={<Error></Error>}></Route>
        <Route path="unauthorized" element={<Unauthorized></Unauthorized>} />
        <Route element={<PersistLogin />}>
          <Route
            element={<RequireAuth allowedRoles={ROLES.Customer}></RequireAuth>}
          >
            <Route path="/customer" element={<CustomerDashLayout />}>
              <Route index element={<Main></Main>}></Route>
              <Route path="settings" element={<Setting></Setting>}></Route>
              <Route path="orders">
                <Route index element={<ViewOrder />} />
                {/*<Route path=":id" element={<EditUser />} />*/}
                <Route path="new" element={<CreateOrder />} />
                <Route path="generateinvoice" element={<ViewPayment />} />
                <Route path="viewinvoices" element={<ViewInvoice/>} />
              </Route>
            </Route>
          </Route>
          <Route
            element={<RequireAuth allowedRoles={ROLES.Admin}></RequireAuth>}
          >
            <Route path="/admin" element={<AdminDashLayout />}>
              <Route index element={<Main></Main>}></Route>

              <Route path="viewInvoices" element={<ViewInvoices></ViewInvoices>}></Route>
              <Route path="duePayments" element={<DuePayments></DuePayments>}></Route>
              <Route path="createInvoice" element={<AddInvoice></AddInvoice>}></Route>
              <Route path="dummyInvoice" element={<CustomInvoice></CustomInvoice>}></Route>
              <Route
                path="addEmployee"
                element={<AddDigitizer></AddDigitizer>}
              ></Route>
              <Route
                path="viewEmployees"
                element={<ViewDigitizers></ViewDigitizers>}
              ></Route>
              <Route
                path="editEmployee/:id"
                element={<EditDigitizer></EditDigitizer>}
              ></Route>
              <Route path="viewCustomer" element={<ViewCustomers />}></Route>
              <Route path="addCustomer" element={<AddCustomer />}></Route>
              <Route path="editCustomer/:id" element={<EditCustomer></EditCustomer>}></Route>
              <Route path="orders">
                <Route index element={<AdminViewOrder></AdminViewOrder>} />
                {/*<Route path=":id" element={<EditUser />} />*/}
                <Route path="pending" element={<AdminViewPendingOrder />} />
                <Route path="new/:id" element={<AdminCreateOrder />} />
                <Route path="assign" element={<ViewAssigned />} />
                <Route path="assign/:id" element={<AssignOrder />} />
                <Route path="reassign/:id" element={<ReAssign />} />
                <Route path="edit/:id" element={<EditOrder />} />
                <Route path="release/:id" element={<ReleaseOrder />} />
              </Route>
              <Route path='reports' element={<Reports></Reports>}></Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import { EditFilled } from '@ant-design/icons';
import { Switch } from 'antd';

export default function ViewDigitizers() {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [employees, setemployees] = useState(null);
  const [error, seterror] = useState(null);
  const [ispending, setispending] = useState(true);

  useEffect(() => {

    let isMounted = true;
    const controller = new AbortController();

    const getEmployees = async () => {

      try {

        const response = await axiosPrivate.get('/api/admin/employee', {
          signal: controller.signal,
        });


        isMounted && setemployees(response.data);
      } catch (err) {

        seterror(err);
        setispending(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getEmployees();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);


  const editEmployee = ()=>{
    
  }

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">View Employees</h3>
        {error && <h3 className="card-title">{error}</h3>}
        <div className="card-tools">
          <div className="input-group input-group-sm" style={{ width: 150 }}>
            <input
              type="text"
              name="table_search"
              className="form-control float-right"
              placeholder="Search"
            />
            <div className="input-group-append">
              <button type="submit" className="btn btn-default">
                <i className="fas fa-search" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card-body table-responsive p-0"
        style={{ height: '80vh' }}
      >
        <table className="table table-head-fixed text-wrap">
          <thead>
            <tr>
              <th>Username</th>
              <th>Status</th>
              <th>Salary</th>
              <th>Commission Type</th>
              <th>Commission</th>
              <th>Contract Details</th>
              <th>Sms Number</th>
              <th>Date Of Joining</th>
              <th>Employee Type</th>
              <th>Actions</th>

            </tr>
          </thead>
          <tbody>
            {employees &&
              employees.map((employee) => (
                <tr>
                  <td>{employee?.username}</td>
                  <td>{employee?.status ?  <h1 className='badge badge-success'>Active</h1>  : <h1 className='badge badge-danger'>InActive</h1>  }</td>
                  <td>{employee?.salary || "N/A"}</td>
                  <td>{employee?.commissionType|| 'N/A'}</td>
                  <td>{employee?.commission|| 'N/A'}</td>
                  <td>{employee?.contractDetails|| 'N/A'}</td>
                  <td>{employee?.smsNumber|| 'N/A'}</td>
                  <td>{employee?.dateOfJoining|| 'N/A'}</td>
                  <td>{employee?.employeeType}</td>
                  <td>
                    <div className="icon-container px-2" >
                      <Link to={`/admin/editEmployee/${employee.id}`}><EditFilled style={{ fontSize: '25px', color: '#023047'}} /></Link>
                      <span className="text bg-dark" style={{zIndex:5}}>Edit digitizer</span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

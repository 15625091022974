import React, { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import {
  EditFilled,
  UploadOutlined,
  RetweetOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { formatDate, timePassed } from '../Utils/date';
import FilePreview from '../Utils/FilePreview ';
import { Skeleton, notification, Popconfirm, Popover, Button, Input, Pagination } from 'antd';
import getCurrencySymbol from '../Utils/currencySymbol';
import QuoteConvert from './QuoteConvert';

const { Search } = Input;

export default function ViewOrder() {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [search, setSearch] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orders, setorders] = useState(null);
  const [files, setfiles] = useState(null);
  const [error, seterror] = useState(null);
  const [ispending, setispending] = useState(true);
  const [refresh, setrefresh] = useState(true);
  const [totalPages, settotalPages] = useState([1]);
  const [page, setpage] = useState('');
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getAllOrders = async () => {
      try {
        const response = await axiosPrivate.get('/api/admin/order', {
          signal: controller.signal,
        });
        console.log(response.data);
        isMounted && setorders(response.data.data);

        settotalPages(response.data.totalItems)

        seterror("")
        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
      }
    };

    getAllOrders();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [refresh]);

  const handlePaginate = (page, pageSize) => {
    console.log(page, pageSize)
    let isMounted = true;
    const controller = new AbortController();

    const getAllOrders = async () => {
      try {
        const response = await axiosPrivate.get('/api/admin/order', {
          signal: controller.signal,
          params: { page, pageSize },
        });

        isMounted && setorders(response.data.data);

        settotalPages(response.data.totalItems)
        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
      }
    };

    getAllOrders();

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const releaseAgain = async (id) => {
    console.log('first');
    setIsSubmitting(true);
    let isMounted = true;
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.get(
        `/api/admin/order/released/${id}`,
        {
          signal: controller.signal,
        },
      );
      notification.open({
        message: 'Order Released Again Successfully',
        // description:
        //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        // onClick: () => {
        //   console.log('Notification Clicked!');
        // },
      });
      setIsSubmitting(false);
    } catch (error) {
      notification.open({
        message: 'Error While Releasing Order Again ',
        // description:
        //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        // onClick: () => {
        //   console.log('Notification Clicked!');
        // },
      });
      setIsSubmitting(false);
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const handleDownload = (filename) => {
    // window.open(`${files}/${filename}`, '_blank');
  };
  const handleDelete = (id) => {
    setIsSubmitting(true);
    let isMounted = true;
    const controller = new AbortController();

    const deleteOrder = async () => {
      try {
        const response = await axiosPrivate.delete(`/api/admin/order/${id}`, {
          signal: controller.signal,
        });

        notification.open({
          message: 'Order Deleted Successfully',
          // description:
          //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
          // onClick: () => {
          //   console.log('Notification Clicked!');
          // },
        });
        refresh ? setrefresh(false) : setrefresh(true);
        setIsSubmitting(false);
      } catch (err) {
        seterror(err);
        setispending(false);
      }
    };

    deleteOrder();

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const handleSearch = (searchText) => {

    let isMounted = true;
    const controller = new AbortController();

    const getSearchOrders = async () => {
      try {
        const response = await axiosPrivate.get('/api/admin/order/search', {
          signal: controller.signal,
          params: { searchText },
        });

        if (response.data.length) {
          seterror("");
          isMounted && setorders(response.data);
        }
        else {
          setorders("");
          seterror(`No Result Found`);
        }



        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
      }
    };
    if (searchText) {
      setispending(true);
      setorders("");
      seterror("")
      getSearchOrders();

      return () => {
        isMounted = false;
        controller.abort();
      };

    }
    else {
      refresh ? setrefresh(false) : setrefresh(true)
    }

  };



  return (
    <>
      <div className="container">
        {error && <h4 className="bg-warning text-dark text-center rounded rounded-pill">{error}</h4>}

      </div>
      <div className="card">
        <div className="card-header bg-dark text-light">
          <h3 className="">All Orders</h3>
          <div>


            {/* <div className="card-tools"> */}
            <div className="input-group input-group-sm my-2" >
              <Search placeholder="input search text" onSearch={value => handleSearch(value)} enterButton />
            </div>
            {/* </div> */}

          </div>
        </div>
        <div
          className="card-body table-responsive p-0"
          style={{ height: '80vh' }}
        >
          <Skeleton active loading={ispending} size={'large'}></Skeleton>

          {orders && (
            <>
              <table
                className="table table-head-fixed text-wrap font-weight-bold"
                style={{ fontSize: '0.9rem' }}
              >
                <thead className="bg-warning text-dark">
                  <tr>
                    <th>#</th>
                    <th>Customer Name</th>
                    <th>Time</th>
                    <th>Design name</th>
                    <th>Order Type</th>
                    <th>Order Priority</th>
                    <th>Order Category</th>
                    <th>Status</th>
                    <th>Payment Status</th>
                    <th>Price</th>
                    <th>Files</th>
                    <th>Actions</th>

                    {/*<th>Design Placement</th>
              <th>Files</th>
              <th>Color Preference</th>
              <th>Garment Material</th>
              <th>Dimension</th>
              <th>Instruction</th>
            <th>Email to Send Copy</th>*/}
                  </tr>
                </thead>
                <tbody className="text-capitalize">
                  {orders.map((order) => (
                    <tr key={order?.serialNumber}>
                      {/*<td>
                      {order.releasedFiles.map((file) => (
                        <button
                          onClick={() => {
                            handleDownload(file.filename);
                          }}
                        >
                          click
                        </button>
                      ))}
                        </td>*/}
                      <td>{order?.serialNumber}</td>
                      <td>{order?.Customer.customer_name}</td>
                      <td style={{ fontSize: '1.5vh' }}>
                        {formatDate(order?.createdAt)}
                        <br />{' '}
                        <span className="text-danger">
                          {timePassed(order?.createdAt)}
                        </span>
                      </td>
                      <td>{order?.design_name}</td>
                      <td>{order?.order_type}</td>
                      {order.order_priority == 'Normal' && (
                        <td>
                          <span class="badge bg-success">
                            {order.order_priority}
                          </span>
                        </td>
                      )}
                      {order.order_priority == 'URGENT' && (
                        <td>
                          <span class="badge bg-purple">
                            {order.order_priority}
                          </span>
                        </td>
                      )}
                      {order.order_priority == 'SUPER URGENT' && (
                        <td>
                          <span class="badge bg-danger">
                            {order.order_priority}
                          </span>
                        </td>
                      )}

                      <td>{order.order_category}</td>
                      <td>
                        {order?.order_status == 'On Hold' && (
                          <span class="badge bg-lime">
                            {order?.order_status}
                          </span>
                        )}
                        {order?.order_status == 'Cancel' && (
                          <span class="badge bg-gray">
                            {order?.order_status}
                          </span>
                        )}
                        {order?.order_status == 'Released' && (
                          <span class="badge bg-success">
                            {order?.order_status}
                          </span>
                        )}
                        {order?.order_status == 'In Progress' && (
                          <span class="badge bg-warning">
                            {order?.order_status}
                          </span>
                        )}
                      </td>
                      <td>{order?.payment_status}</td>
                      <td>
                        <span className="input-group-text fw-b bg-primary">
                          {getCurrencySymbol(order.Customer.currencyType)}{' '}
                          {order?.price}
                        </span>
                      </td>

                      <td>
                        <Popover
                          placement="left"
                          content={
                            <div className="container">
                              <div className="row">
                                <div className="col-6 border">
                                  <p class="">Released Files</p>
                                  <hr />
                                  {order.releasedFiles.map((file, index) => (
                                    <FilePreview
                                      key={index}
                                      fileName={file.filename}
                                      fileUrl={file.filename}
                                    ></FilePreview>
                                  ))}
                                </div>
                                <div className="col-6 border">
                                  <p class="">Customer Files</p>
                                  <hr />
                                  {order.orderFiles.map((file, index) => (
                                    <FilePreview
                                      key={index}
                                      fileName={file.filename}
                                      fileUrl={file.filename}
                                    ></FilePreview>
                                  ))}
                                </div>
                              </div>
                            </div>
                          }
                          title="---------------------All Files---------------------"
                        >
                          <Button type="primary">View Files</Button>
                        </Popover>
                      </td>

                      <div className="icon-container m-0">
                        <td>
                          <Link
                            className="btn bg-light p-0"
                            to={`/admin/orders/edit/${order.id}`}
                          >
                            <EditFilled
                              style={{ fontSize: '1.2rem', color: '#023047' }}
                            />
                          </Link>
                        </td>
                        <span
                          className="text bg-warning"
                          style={{ zIndex: '9' }}
                        >
                          Edit Order
                        </span>
                      </div>
                      {order.isAssigned && (
                        <div className="icon-container m-0">
                          <td>
                            <Link
                              className="btn bg-light p-0"
                              to={`/admin/orders/release/${order.id}`}
                            >
                              <UploadOutlined
                                className="text-danger"
                                style={{ fontSize: '1.2rem' }}
                              />
                            </Link>
                          </td>
                          <span
                            className="text bg-warning"
                            style={{ zIndex: '9' }}
                          >
                            Upload Order
                          </span>
                        </div>
                      )}
                      {order.order_status == 'Released' && (
                        <div className="icon-container">
                          <td>
                            <button
                              className="btn bg-light p-0"
                              onClick={() => {
                                releaseAgain(order.id);
                              }}
                              disabled={isSubmitting}
                            >
                              <RetweetOutlined
                                className="text-primary"
                                style={
                                  isSubmitting
                                    ? {
                                      fontSize: '1.2rem',
                                      cursor: 'not-allowed',
                                    }
                                    : { fontSize: '1.2rem' }
                                }
                              />
                            </button>
                          </td>
                          <span
                            className="text bg-warning"
                            style={{ zIndex: '9' }}
                          >
                            {isSubmitting ? 'Please Wait' : 'Release Again'}
                          </span>
                        </div>
                      )}
                      <div className="icon-container m-0">
                        <td>
                          <Popconfirm
                            title="Delete Order"
                            description="Are you sure to delete this Order?"
                            onConfirm={() => {
                              handleDelete(order.id);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button
                              className="btn bg-light p-0"
                              disabled={isSubmitting}
                            >
                              <DeleteOutlined
                                style={{ fontSize: '1.2rem', color: 'red' }}
                              />
                            </button>
                          </Popconfirm>
                        </td>
                        <span
                          className="text bg-warning"
                          style={{ zIndex: '9' }}
                        >
                          Delete Order
                        </span>
                      </div>
                      {(order.order_type == 'Embroidery Digitizing Quote' ||
                        order.order_type == 'Vector Art Quote' ||
                        order.order_type == 'Both Embroidery Digitizing & Vector Art Quotes') && <div className="icon-container">
                          <td>
                            <QuoteConvert id={order.id} />
                          </td>
                          <span
                            className="text bg-warning"
                            style={{ zIndex: '9' }}
                          >
                            Convert Quote
                          </span>
                        </div>}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div class="card-footer clearfix">
                <Pagination
                  showSizeChanger
                  onShowSizeChange={handlePaginate}
                  defaultCurrent={1}
                  total={totalPages}
                  onChange={(page, pageSize) => { handlePaginate(page, pageSize) }}
                />

              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

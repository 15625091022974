import axios from '../api/axios';
import React, { useState } from 'react';
import useAuth from '../hooks/useAuth';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { Input } from 'antd';

export default function Register() {
  const [values, setValues] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [username, setusername] = useState(null);
  const [email, setemail] = useState(null);
  const [password, setpassword] = useState('');
  const [confirmPassword, setconfirmPassword] = useState('');
  const [currencyType, setcurrencyType] = useState('USD');
  const [errMsg, setErrMsg] = useState(null);
  const [referral , setreferral ] = useState(null);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  let handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // let { email, password, username } = values;
    const data = { username,email,password,confirmPassword, currencyType,referral };
    let isMounted = true;
    const controller = new AbortController();
    const getOrders = async () => {
      // console.log("inside getorders function")
      e.preventDefault();
      try {
        const response = await axios.post('/api/user/signup', data, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const accessToken = response?.data?.accessToken;
        const roles = response?.data?.roles;

        setAuth({
          email: response?.data?.email,
          roles,
          accessToken,
          username: response?.data?.username,
        });
        // setemail('')
        // setpassword('')
        roles == 'admin' ? navigate('/admin') : navigate('/customer');
        setErrMsg("")
      } catch (error) {
        setErrMsg(error.response.data.error)
        setIsSubmitting(false);

      }
    };

    getOrders();

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

 

  return (

    <>
  
    <div className="bg-log">
    <div className="container-fluid">
      <div className="row justify-content-center" style={{ height: '100vh' }}>
        <div className="col-12 col-md-5 align-self-center">
          
          <div className="card card-success bg-card-ini">
            <div className="card-body text-white">
              <h3 className="text-center" style={{fontSize:"4vw"}}><UserAddOutlined /> </h3>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="formInput">
                  <label className="form-label">Username</label>
                  <Input
                    size="large"
                    placeholder="Enter Username (only alphabets space is not allowed)"
                    prefix={<UserOutlined />}
                    onChange={(e)=>{setusername(e.target.value)}}
                    
                  />
                  </div>
                  <div className="formInput">
                  <label className="form-label">Email</label>
                  <Input
                  type='email'
                    size="large"
                    placeholder="Enter Email"
                    prefix={<UserOutlined />}
                    onChange={(e)=>{setemail(e.target.value)}}
                  />
                </div>
                <div className="formInput">
                  <label className="form-label">Password</label>
                  <Input.Password
                  type='password'
                    size="large"
                    placeholder="Enter Password 8-15 characters"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={(e)=>{setpassword(e.target.value)}}
                  />
                </div>
                {/*<div className="formInput">
                  <label className="form-label">Confirm Password</label>
                  <Input.Password
                  type='password'
                  
                    size="large"
                    placeholder="Enter Confirm Password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={(e)=>{setconfirmPassword(e.target.value)}}
                  />
                  </div>*/}
                <div className="form-group">
                    <label for="">Currency Type</label>
                    <select
                      class="form-control"
                      onChange={(e) => {
                        setcurrencyType(e.target.value);
                      }}
                    >
                      <option value={'USD'} >USD</option>
                      <option value={'CAD'} >CAD</option>
                      <option value={'GBP'} >GBP</option>
                      <option value={'EUR'} >EUR</option>
                      <option value={'AUD'} >AUD</option>
                      <option value={'JPY'} >JPY</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label for="">How did you hear about us? </label>
                    <input type="text" className="form-control" placeholder='eg: google, sales representative etc' onChange={(e)=>{setreferral(e.target.value)}} />
                  </div>
                <div className="text-center my-4">
                  <button
                    className="btn btn-primary btn-log w-50"
                    disabled={isSubmitting}
                  >
                    Register
                  </button>
                </div>
                <Link
                  to={'/login'}
                  className="my-4 text-center text-white font-weight-bolder d-block"
                >
                  Already have an Account
                </Link>
              </form>
              {errMsg && (
                <div className="bg-danger text-center rounded rounded-pill">
                  {errMsg}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  );
}

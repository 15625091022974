import React, { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import { UserAddOutlined, EditFilled } from '@ant-design/icons';
import { formatDate, timePassed } from '../Utils/date';
import { Skeleton, Popover, Button, Input } from 'antd';
import FilePreview from '../Utils/FilePreview ';
const { Search } = Input

export default function AdminViewPendingOrder() {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [pendingOrders, setpendingOrders] = useState(null);
  const [error, seterror] = useState(null);
  const [ispending, setispending] = useState(true);
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getAllOrders = async () => {
      try {
        const response = await axiosPrivate.get('/api/admin/order/pending', {
          signal: controller.signal,
        });
        setpendingOrders(response.data);
        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
      }
    };

    getAllOrders();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  const openFileInNewTab = (base64Data) => {
    const fileType = getFileType(base64Data);
    const blob = b64toBlob(base64Data);
    const url = URL.createObjectURL(blob);
    window.open(url);
  };

  const getFileType = (base64Data) => {
    const data = atob(base64Data);
    const bytes = new Uint8Array(data.length);

    for (let i = 0; i < data.length; i++) {
      bytes[i] = data.charCodeAt(i);
    }

    let fileType = '';
    const uint = bytes.subarray(0, 4);
    const header = [...uint]
      .map((byte) => byte.toString(16))
      .join('')
      .toUpperCase();

    switch (header) {
      case '89504E47':
        fileType = 'image/png';
        break;
      case 'FFD8FFDB':
      case 'FFD8FFE0':
      case 'FFD8FFE1':
        fileType = 'image/jpeg';
        break;
      case '25504446':
        fileType = 'application/pdf';
        break;
      default:
        fileType = 'application/octet-stream';
        break;
    }

    return fileType;
  };

  const b64toBlob = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays);
    return blob;
  };
  const handleSearch = (searchText) => {
    let isMounted = true;
    const controller = new AbortController();
    const getAllSearchOrders = async () => {
      try {
        const response = await axiosPrivate.get('/api/admin/order/pending/search', {
          signal: controller.signal,
          params: { searchText },
        });
        if(response.data.length){
          seterror("");
          isMounted &&setpendingOrders(response.data);
        }
        else{
          setpendingOrders("");
          seterror(`No Result Found`);
        }
        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
      }
    };
    if(searchText){
      setispending(true);
      setpendingOrders("");
      seterror("")
      getAllSearchOrders();
  
      return () => {
        isMounted = false;
        controller.abort();
      };

    }

    

    
  };
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Pending Orders</h3>
        
        <div className="card-tools">
          <div className="input-group input-group-sm" style={{ width: 150 }}>
            <Search placeholder="input search text" onSearch={value => handleSearch(value)} enterButton />
          </div>
        </div>
      </div>
      <div
        className="card-body table-responsive p-0"
        style={{ height: '80vh' }}
      >
        <Skeleton active loading={ispending} size={'large'}></Skeleton>
        {error && <h3 className="card-title">{error}</h3>}
        {pendingOrders && (
          <table
            className="table table-head-fixed text-wrap font-weight-bold"
            style={{ fontSize: '0.9rem' }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Customer Name</th>
                <th>Time</th>
                <th>Design name</th>
                <th>Order Type</th>
                <th>Instruction</th>
                <th>Order Priority</th>
                <th>Order Category</th>
                <th>Status</th>

                <th>Customer Files</th>
                <th>Actions</th>
                {/*<th>Design Placement</th>
                <th>Files</th>
                <th>Color Preference</th>
                <th>Garment Material</th>
                <th>Dimension</th>
                <th>Instruction</th>
                <th>Order Priority</th>
               <th>Email to Send Copy</th>*/}
              </tr>
            </thead>
            <tbody className="text-capitalize">
              {pendingOrders.map((order) => (
                <tr key={order?.serialNumber}>
                  <td>{order?.serialNumber}</td>
                  <td>{order?.Customer.customer_name}</td>
                  <td style={{ fontSize: '1.5vh' }}>
                    {formatDate(order?.createdAt)}
                    <br />{' '}
                    <span className="text-danger">
                      {timePassed(order?.createdAt)}
                    </span>
                  </td>
                  <td>{order?.design_name}</td>
                  <td>{order.order_type}</td>
                  <td>{order.instruction}</td>
                  {order.order_priority == 'Normal' && (
                    <td>
                      <span class="badge bg-success">
                        {order.order_priority}
                      </span>
                    </td>
                  )}

                  {order.order_priority == 'URGENT' && (
                    <td>
                      <span class="badge bg-purple">
                        {order.order_priority}
                      </span>
                    </td>
                  )}
                  {order.order_priority == 'SUPER URGENT' && (
                    <td>
                      <span class="badge bg-danger">
                        {order.order_priority}
                      </span>
                    </td>
                  )}

                  <td>{order.order_category}</td>

                  <td>
                    {order?.order_status == 'On Hold' && (
                      <span class="badge bg-lime">{order?.order_status}</span>
                    )}
                    {order?.order_status == 'Cancel' && (
                      <span class="badge bg-gray">{order?.order_status}</span>
                    )}
                    {order?.order_status == 'Released' && (
                      <span class="badge bg-success">
                        {order?.order_status}
                      </span>
                    )}
                    {order?.order_status == 'In Progress' && (
                      <span class="badge bg-warning">
                        {order?.order_status}
                      </span>
                    )}
                  </td>

                  <td>
                    <Popover
                      content={
                        <div>
                          <hr />
                          <small className="d-block fw-b">Customer Files</small>
                          {order.orderFiles.map((file, index) => (
                            <FilePreview
                              key={index}
                              fileName={file.filename}
                              fileUrl={file.filename}
                            ></FilePreview>
                          ))}
                        </div>
                      }
                      title="All Files"
                    >
                      <Button type="primary">View Files</Button>
                    </Popover>
                  </td>
                  <div className="icon-container">
                    <td>
                      <Link to={`/admin/orders/edit/${order.id}`}>
                        <EditFilled
                          style={{ fontSize: '25px', color: '#023047' }}
                        />
                      </Link>
                    </td>
                    <span className="text bg-warning" style={{ zIndex: '9' }}>
                      Edit Order
                    </span>
                  </div>
                  <div className="icon-container">
                    <td>
                      <Link to={`/admin/orders/assign/${order.id}`}>
                        <UserAddOutlined
                          style={{ fontSize: '25px', color: '#9b2226' }}
                        />{' '}
                      </Link>
                    </td>
                    <span className="text bg-warning" style={{ zIndex: '9' }}>
                      Assign Order
                    </span>
                  </div>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

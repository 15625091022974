import React from 'react'

export default function Error() {
    return (
        <div className="section bg-danger">
          <div className="container">
            <div
              className="row justify-content-center align-content-center"
              style={{ height: '100vh' }}
            >
              <div className="col-12 col-md-8 bg-white py-5 elevation-3">
                <div className="text-center">
                  <img
                    src="/fav.ico"
                    alt="aacreative Logo"
                    className="brand-image"
                    style={{ height: '6vw' }}
                  />
                  <h1 className="text-center">AA Creative EMB</h1>
                </div>
                <div className="ribbon-wrapper ribbon-lg ">
                  <div className="ribbon bg-danger text-lg elevation-3">Error</div>
                </div>
                <div className="text-center">
                  <i class="icon fas fa-window-close fa-8x text-danger"></i>
                </div>
                <h5 className="text-center fw-bolder my-5">
                We regret to inform you that the payment was unsuccessful. An error occurred during the transaction process. Kindly contact the administrator for further assistance.
                </h5>
              </div>
            </div>
          </div>
        </div>
      );
}

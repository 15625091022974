import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Dropzone from 'react-dropzone';
import options from '../Utils/options';
import FilePreview from '../Utils/FilePreview ';
export default function ReAssign() {
  const { id } = useParams();
  const location = useLocation();
  let navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [design_name, setdesign_name] = useState(null);
  const [order_type, setorder_type] = useState(null);
  const [design_placement, setdesign_placement] = useState(null);
  const [files, setfiles] = useState([]);
  const [color_preference, setcolor_preference] = useState(null);
  const [garment_material, setgarment_material] = useState(null);
  const [dimension, setdimension] = useState(null);
  const [instruction, setinstruction] = useState(null);
  const [order_priority, setorder_priority] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [orderDetails, setorderDetails] = useState(null);
  const [order_id, setorder_id] = useState(null);
  const [employees, setemployees] = useState(null);
  const [error, seterror] = useState(null);
  const [ispending, setispending] = useState(true);
  const [employee_id, setemployee_id] = useState(null);
  const [customerFiles, setcustomerFiles] = useState([]);
  const [adminFiles, setadminFiles] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getData = async () => {
      try {
        const { data: orderData } = await axiosPrivate.get(
          `/api/admin/assign/${id}`,
          {
            signal: controller.signal,
          },
        );
      
        setorderDetails(orderData);
        setdesign_name(orderData.design_name);
        setdesign_placement(
          JSON.parse(orderData.design_placement).map((item) => ({
            label: item,
            value: item,
          })),
        );
        setcolor_preference(orderData.color_preference);
        setgarment_material(orderData.garment_material);
        setdimension(orderData.dimension);
        setinstruction(orderData.instruction);
        setorder_id(orderData.order_id);
        setorder_priority(orderData.order_priority);
        setcustomerFiles(orderData.customerFiles);
        setadminFiles(orderData.adminFiles);
        setemployee_id(orderData.employee_id);
        setorder_type(orderData.Order.order_type);
        const { data: employeeData } = await axiosPrivate.get(
          '/api/admin/employee',
          {
            signal: controller.signal,
          },
        );
        let filter =[]
        if(orderData.order_type==="Embroidery Digitizing Order"||orderData.order_type==="Embroidery Digitizing Quote"){
          // console.log("in if",orderData.order_type)
          filter = employeeData.filter(employee=>employee.employeeType == 'Digitizer' && employee.status  )
          setemployee_id(filter[0].id)
        }
        else if(orderData.order_type==="Vector Art Order" || orderData.order_type==="Vector Art Quote"){
          // console.log("in if else",orderData.order_type)
          filter = employeeData.filter(employee=>employee.employeeType == 'Vectorizer'&& employee.status)
          setemployee_id(filter[0].id)
        }
        else{
          // console.log("in else",orderData.order_type)
          filter = employeeData.filter(employee=>employee.status)
          setemployee_id(filter[0].id)
        }

        setemployees(filter)
      } catch (error) {
    
      }
    };

    getData();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    let isMounted = true;
    const controller = new AbortController();

    const postAssignOrder = async () => {
      let isMounted = true;
      const controller = new AbortController();

      try {
        const response = await axiosPrivate.patch(
          `/api/admin/assign/${id}`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            signal: controller.signal,
          },
        );

        isMounted && navigate('/admin/orders/assign');
      } catch (err) {
        console.error(err);
        setIsSubmitting(false);

        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    const arrayDesign = design_placement.map((item) => item?.value);
    const stringDesign = JSON.stringify(arrayDesign);
    formData.append('order_priority', order_priority);
    formData.append('design_name', design_name);
    formData.append('design_placement', stringDesign);
    formData.append('color_preference', color_preference);
    formData.append('garment_material', garment_material);
    formData.append('dimension', dimension);
    formData.append('instruction', instruction);
    formData.append('order_id', order_id);
    formData.append('employee_id', employee_id);
    formData.append('customerFiles', JSON.stringify(customerFiles));
    formData.append('adminFiles', JSON.stringify(adminFiles));

    selectedFiles.length > 0
      ? selectedFiles.forEach((item) => formData.append('files', item))
      : setSelectedFiles('');
    // formData.append('digitizer_user_id', digitizer_user_id);

    postAssignOrder();
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const handleDrop = (acceptedFiles) => {
    // Calculate the total size of all selected files
    const totalSize =
      selectedFiles.reduce((acc, file) => acc + file.size, 0) +
      acceptedFiles.reduce((acc, file) => acc + file.size, 0);

    // Check if the total size exceeds 100 megabytes
    if (totalSize > 100 * 1024 * 1024) {
     
      return;
    }

    setSelectedFiles([...selectedFiles, ...acceptedFiles]);
  };

  const handleRemoveCustomer = (index) => {
    let filterfiles = customerFiles.filter(
      (item, itemindex) => itemindex !== index,
    );

    setcustomerFiles(filterfiles);
  };

  const handleRemoveAdmin = (index) => {
    let filterfiles = adminFiles.filter(
      (item, itemindex) => itemindex !== index,
    );

    setadminFiles(filterfiles);
  };
  return (
    <div className="container">
      <div className="row my-4">
        <div className="card card-success">
          <div className="card-header">
            <h3 className="card-title">ReAssign Order</h3>
          </div>
          <div className="card-body">
            {orderDetails && (
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="form-group">
                  <label for="">
                    {order_type === 'Embroidery Digitizing Order' ||
                    order_type === 'Embroidery Digitizing Quote'
                      ? 'Digitizers'
                      : order_type === 'Vector Art Order' ||
                        order_type === 'Vector Art Quote'
                      ? 'Vectorizers'
                      : 'Vectorizers/Digitizer'}
                   
                  </label>
                  <select
                    class="form-control"
                    onChange={(e) => {
                      setemployee_id(e.target.value);
                    }}
                    value={employee_id}
                  >
                    {employees &&
                      employees.map((employee) => (
                        <option value={employee.id}>{employee.username}</option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="">Order Priority</label>
                  <select
                    className="form-control"
                    defaultValue={order_priority}
                    onChange={(e) => {
                      setorder_priority(e.target.value);
                    }}
                  >
                    {order_priority == 'Normal' ? (
                      <option value="Normal" selected>
                        Normal - No Rush, Need Anytime Today or Tomorrow
                      </option>
                    ) : (
                      <option value="Normal">
                        Normal - No Rush, Need Anytime Today or Tomorrow
                      </option>
                    )}
                    {order_priority == 'Normal' ? (
                      <option value="URGENT" selected>
                        URGENT - Need By Tomorrow Morning!
                      </option>
                    ) : (
                      <option value="URGENT">
                        URGENT - Need By Tomorrow Morning!
                      </option>
                    )}
                    {order_priority == 'Normal' ? (
                      <option value="SUPER URGENT" selected>
                        SUPER URGENT - Need Today!
                      </option>
                    ) : (
                      <option value="SUPER URGENT">
                        SUPER URGENT - Need Today!
                      </option>
                    )}
                  </select>
                </div>
                <div className="form-group">
                  <label for="">Design Name P.O Number</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Design Name P.O Number"
                    value={design_name}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label for="">Order Type</label>
                  <select
                    class="form-control"
                    onChange={(e) => {
                      setorder_type(e.target.value);
                    }}
                    defaultValue={order_type}
                    disabled
                  >
                    <option value="Embroidery Digitizing Order">
                      Embroidery Digitizing Order
                    </option>
                    <option value="Embroidery Digitizing Quote">
                      Embroidery Digitizing Quote
                    </option>
                    <option value="Vector Art Order">Vector Art Order</option>
                    <option value="Vector Art Quote">Vector Art Quote</option>
                    <option value="Both Embroidery Digitizing & Vector Art Orders">
                      Both Embroidery Digitizing & Vector Art Orders
                    </option>
                    <option value="Both Embroidery Digitizing & Vector Art Quotes">
                      Both Embroidery Digitizing & Vector Art Quotes
                    </option>
                  </select>
                </div>
                <div>
                  <label for="exampleInputEmail1">Placement of Design</label>

                  <MultiSelect
                    options={options}
                    value={design_placement}
                    onChange={setdesign_placement}
                    labelledBy="Select"
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Color Preference</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Color Preference"
                    onChange={(e) => {
                      setcolor_preference(e.target.value);
                    }}
                    defaultValue={color_preference}
                  />
                </div>

                <div className="form-group">
                  <label for="">Garment Material</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Garment Material"
                    onChange={(e) => {
                      setgarment_material(e.target.value);
                    }}
                    defaultValue={garment_material}
                  />
                </div>
                <div className="form-group">
                  <label for="">Dimension(width x height)</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Dimension(width x height)"
                    onChange={(e) => {
                      setdimension(e.target.value);
                    }}
                    defaultValue={dimension}
                  />
                </div>
                <div className="text-center form-group">
                  <h3 className="my-2">
                    Instructions or Any Additional Notes:
                  </h3>
                  <small>
                    Instructions are not required but help in improving quality.
                    For example if you want to run this design on any special
                    fabric like fleeces or black color garments please write
                    here to get expected / best results in the first attempt.
                  </small>
                  <textarea
                    class="form-control"
                    rows="3"
                    placeholder="Enter Additional Notes"
                    onChange={(e) => {
                      setinstruction(e.target.value);
                    }}
                    defaultValue={instruction}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label className="form-label">Upload More Files</label>
                  <div class="bg-danger p-2 rounded rounded-3">
                    Warning! Upload multiple files but size not exceed more than
                    100 MB
                  </div>

                  <Dropzone
                    onDrop={handleDrop}
                    multiple
                    maxSize={100 * 1024 * 1024}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="btn btn-primary my-2 w-100">
                          Drag and drop files here, or click to select files
                        </p>
                        {selectedFiles.length > 0 && (
                          <div>
                            <h4>Selected Files:</h4>

                            <ul className="list-unstyled">
                              {selectedFiles.map((file) => (
                                <li className="list-group-item" key={file.name}>
                                  {file.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                  <label className="form-label">Customer Uploaded Files</label>
                  <ul className="list-unstyled">
                    {customerFiles.map((file, index) => (
                      <li className="list-group-item" key={index}>
                        <FilePreview
                          key={index}
                          fileName={file.filename}
                          fileUrl={file.filename}
                        ></FilePreview>
                        <button
                          type="button"
                          className="close"
                          style={{ marginTop: '-20px' }}
                          onClick={() => handleRemoveCustomer(index)}
                        >
                          X
                        </button>
                      </li>
                    ))}
                  </ul>
                  <label className="form-label">Admin Uploaded Files</label>
                  <ul className="list-unstyled">
                    {adminFiles.map((file, index) => (
                      <li className="list-group-item" key={index}>
                        <FilePreview
                          key={index}
                          fileName={file.filename}
                          fileUrl={file.filename}
                        ></FilePreview>
                        <button
                          type="button"
                          className="close"
                          style={{ marginTop: '-20px' }}
                          onClick={() => handleRemoveAdmin(index)}
                        >
                          X
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="text-center">
                  <input
                    type="submit"
                    className="btn btn-success my-3 w-50"
                    value={'Assign Order'}
                    disabled={isSubmitting}
                  />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { notification, Popconfirm } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';

export default function QuoteConvert({id}) {


    const axiosPrivate = useAxiosPrivate();
    const [isSubmitting,setIsSubmitting] = useState(false)

    const handleConvertquote = async (id) => {

        setIsSubmitting(true);
        let isMounted = true;
        const controller = new AbortController();
        try {
            const response = await axiosPrivate.get(
                `/api/admin/order/convert/${id}`,
                {
                    signal: controller.signal,
                },
            );
            
            notification.open({
                message: 'quote Converted To Order',
                // description:
                //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
                // onClick: () => {
                //   console.log('Notification Clicked!');
                // },
            });
            setIsSubmitting(false);
        } catch (error) {
            notification.open({
                message: 'Error While quote Converted To Order' ,
                // description:
                //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
                // onClick: () => {
                //   console.log('Notification Clicked!');
                // },
            });
            setIsSubmitting(false);
        }
        return () => {
            isMounted = false;
            controller.abort();
        };
    };


    return (
        <>
        
            <Popconfirm
                title="Convert quote"
                description="Are you sure to COnvert this quote?"
                onConfirm={() => {
                    handleConvertquote(id);
                }}
                okText="Yes"
                cancelText="No"
            >
                <button
                    className="btn bg-light p-0"
                    disabled={isSubmitting}
                >
                
                    <RollbackOutlined
                        style={{ fontSize: '1.2rem', color: 'purple' }}
                    />
                </button>
            </Popconfirm>
        </>
    )
}


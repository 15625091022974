import React from 'react';

export default function Success() {
  return (
    <div className="section bg-teal">
      <div className="container">
        <div
          className="row justify-content-center align-content-center"
          style={{ height: '100vh' }}
        >
          <div className="col-12 col-md-8 bg-white py-5 elevation-3">
            <div className="text-center">
              <img
                src="/fav.ico"
                alt="aacreative Logo"
                className="brand-image"
                style={{ height: '6vw' }}
              />
              <h1 className="text-center">AA Creative EMB</h1>
            </div>
            <div className="ribbon-wrapper ribbon-lg ">
              <div className="ribbon bg-teal text-lg elevation-3">Success</div>
            </div>
            <div className="text-center">
              <i class="icon fas fa-check fa-8x text-teal"></i>
            </div>
            <h5 className="text-center fw-bolder my-5">
            Thank you for your payment. We have received it successfully, and your invoice has been sent to your designated email address.
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import { UsergroupAddOutlined, UploadOutlined } from '@ant-design/icons';
import { formatDate, timePassed } from '../Utils/date';
import { Skeleton, Popover, Button, Input } from 'antd';
import FilePreview from '../Utils/FilePreview ';
const { Search } = Input

export default function ViewAssigned() {
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [orders, setorders] = useState(null);
  const [error, seterror] = useState(null);
  const [ispending, setispending] = useState(true);
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getAllAssignedOrders = async () => {
      try {
        const response = await axiosPrivate.get('/api/admin/assign', {
          signal: controller.signal,
        });

        isMounted && setorders(response.data);
        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getAllAssignedOrders();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  const handleSearch = (searchText) => {
    let isMounted = true;
    const controller = new AbortController();
    const getAllAssignedOrders = async () => {
      try {
        const response = await axiosPrivate.get('/api/admin/assign/search', {
          signal: controller.signal,
          params: { searchText },
        });
        if (response.data.length) {
          seterror("");
          isMounted && setorders(response.data);
        }
        else {
          setorders("");
          seterror(`No Result Found`);
        }

        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };
    if (searchText) {
      setispending(true);
      setorders("");
      seterror("")

      getAllAssignedOrders();

      return () => {
        isMounted = false;
        controller.abort();
      };
    }
  };
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Assigned Orders</h3>
        <div className="card-tools">
          <div className="input-group input-group-sm" style={{ width: 150 }}>
            <Search placeholder="input search text" onSearch={value => handleSearch(value)} enterButton />
          </div>
        </div>
      </div>
      <div
        className="card-body table-responsive p-0"
        style={{ height: '80vh' }}
      >
        {error && <h3 className="card-title">{error}</h3>}

        <Skeleton active loading={ispending} size={'large'}></Skeleton>
        {orders && (
          <table
            className="table table-head-fixed text-wrap font-weight-bold"
            style={{ fontSize: '1.6vh' }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Customer Name</th>
                <th>Design Name</th>
                <th>Priority</th>
                <th>Order Type</th>
                <th>Order Category</th>
                <th>Status</th>
                <th>Assigned To</th>
                <th>Assigned Date</th>
                <th>Time Passed</th>
                <th>Files</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order?.Order.serialNumber}>
                  <td>{order?.Order.serialNumber} </td>
                  <td>{order?.Order?.Customer?.customer_name}</td>
                  <td>{order?.design_name}</td>
                  <td>{order?.order_priority}</td>
                  <td>{order?.Order.order_type}</td>
                  <td>{order?.Order.order_category}</td>

                  <td>
                    {order?.Order.order_status == 'On Hold' && (
                      <span class="badge bg-lime">
                        {order?.Order.order_status}
                      </span>
                    )}
                    {order?.Order.order_status == 'Cancel' && (
                      <span class="badge bg-gray">
                        {order?.Order.order_status}
                      </span>
                    )}
                    {order?.Order.order_status == 'Released' && (
                      <span class="badge bg-success">
                        {order?.Order.order_status}
                      </span>
                    )}
                    {order?.Order.order_status == 'In Progress' && (
                      <span class="badge bg-warning">
                        {order?.Order.order_status}
                      </span>
                    )}
                  </td>
                  <td>{order?.Employee.username}</td>
                  <td>{formatDate(order?.createdAt)}</td>
                  <td>
                    <span class="badge bg-danger">
                      {timePassed(order?.createdAt)}
                    </span>
                  </td>
                  <td>
                    <Popover
                      content={
                        <div>
                          <hr />
                          <small className="d-block fw-b">Admin Files</small>
                          {order.adminFiles.map((file, index) => (
                            <FilePreview
                              key={index}
                              fileName={file.filename}
                              fileUrl={file.filename}
                            ></FilePreview>
                          ))}
                          <hr className="m-0" />
                          <small className="d-block fw-b">Customer Files</small>
                          {order.customerFiles.map((file, index) => (
                            <FilePreview
                              key={index}
                              fileName={file.filename}
                              fileUrl={file.filename}
                            ></FilePreview>
                          ))}
                        </div>
                      }
                      title="All Files"
                    >
                      <Button type="primary">View Files</Button>
                    </Popover>
                  </td>
                  <div className="icon-container text-center">
                    <td>
                      <Link to={`/admin/orders/reassign/${order.id}`}>
                        <UsergroupAddOutlined
                          className="text-danger"
                          style={{ fontSize: '25px' }}
                        />
                      </Link>
                    </td>
                    <span className="text bg-warning" style={{ zIndex: '9' }}>
                      Reassign Order
                    </span>
                  </div>
                  <div className="icon-container">
                    <td>
                      <Link to={`/admin/orders/release/${order.order_id}`}>
                        <UploadOutlined
                          className="text-danger"
                          style={{ fontSize: '25px' }}
                        />
                      </Link>
                    </td>
                    <span className="text bg-warning" style={{ zIndex: '9' }}>
                      Upload Order
                    </span>
                  </div>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Skeleton, Popover, Button,notification,Input } from 'antd';
import getCurrencySymbol from '../Utils/currencySymbol';
import { formatDate } from '../Utils/date';
const { Search } = Input;
export default function DuePayments() {
  const axiosPrivate = useAxiosPrivate();
  
  const [userIds, setuserIds] = useState([]);
  const [payments, setPayments] = useState(null);
  const [error, seterror] = useState(null);
  const [ispending, setispending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getDues = async () => {
      try {
        const response = await axiosPrivate.get(
          '/api/admin/invoice/duepayments',
          {
            signal: controller.signal,
          },
        );

        isMounted && setPayments(response.data);
        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getDues();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const handleDownload = (id) => {
    setLoading(true);
    let isMounted = true;
    const controller = new AbortController();
    const downloadInvoice = async () => {
      try {
        const response = await axiosPrivate.get(`/api/admin/invoice/getdueInvoice/${id}`, {
          signal: controller.signal,
          responseType: 'arraybuffer',
        });

        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(pdfBlob);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'DueInvoice.pdf');
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        setLoading(false);
      } catch (err) {
        seterror(err);
        setispending(false);
        setLoading(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    downloadInvoice();

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  const emailInvoice = (id) => {
    setLoading(true);
    let isMounted = true;
    const controller = new AbortController();
    const email = async () => {
      try {
        const response = await axiosPrivate.get(`/api/admin/invoice/senddueInvoice/${id}`, {
          signal: controller.signal,
          responseType: 'arraybuffer',
        });
        notification.open({
          message: 'Due Invoice Sent Successfully',
        });
   
        setLoading(false);
      } catch (err) {
        seterror(err);
        setispending(false);
        setLoading(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    email();

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  
  
  
  const handleSelectAll = (allIds,e) => {
  
    
    e.target.checked ? setuserIds(allIds):setuserIds([])
   
  };
  const handleCheckboxChange = (userId) => {
    
    setuserIds((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(userId)) {
      
        return prevSelectedUsers.filter((id) => id !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };
  const sendSelectedUsers = () => {
    console.log(userIds)
      setLoading(true);
      const requests =userIds.map(userid=>{
        const controller = new AbortController();
        return  axiosPrivate.get(`/api/admin/invoice/senddueInvoice/${userid}`,{
          signal: controller.signal,
          responseType: 'arraybuffer',
        });

      })
      Promise.all(requests)
      .then((responses) => {
        // console.log('All users sent successfully:', responses);
        notification.open({
          message: 'Due Invoice Sent to selected users Successfully',
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error sending users:', error);
        seterror(error);

        setLoading(false);

        // Handle errors if necessary
      });
      console.log(requests)
  };
  const handleSearch = (searchText) => {
    setispending(true)
    let isMounted = true;
    const controller = new AbortController();
    const getDuesSearch = async () => {
      try {
        const response = await axiosPrivate.get(
          '/api/admin/invoice/duepayments/search',
          {
            signal: controller.signal,
            params: { searchText },
          },
        );

        if(response.data.length){
          seterror("");
          isMounted && setPayments(response.data);
        }
        else{
          setPayments("");
          seterror(`No Result Found`);
        }
        setispending(false);
      } catch (err) {
        seterror(err);
        setispending(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getDuesSearch();

    return () => {
      isMounted = false;
      controller.abort();
    };
  };
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">View Invoices</h3>
        <div className="card-tools">
          <div className="input-group input-group-sm" style={{ width: 150 }}>
            
          <Search placeholder="input search text" onSearch={value => handleSearch(value)} enterButton />
          </div>
        </div>
      </div>
      <div
        className="card-body table-responsive p-0"
        style={{ fontSize: '0.9em' }}
      >
        <Skeleton active loading={ispending} size={'large'}></Skeleton>
        {error && <h3 className="card-title">{error}</h3>}

        {payments && (
          <table className="table table-head-fixed text-wrap font-weight-bold">
            <thead>
              <tr className='text-center'>
              <td colSpan={8}>
                  {userIds.length!=0 && <Button

                        
                          className='w-100'
                          type="primary"
                          loading={loading}
                          onClick={() => {
                            sendSelectedUsers();
                          }}
                        >
                          Send Due Invoice To Selected Users
                  </Button>}
              
              </td>
              </tr>
              <tr>
                <th><input type="checkbox" 
                          
                onChange={(e)=>{handleSelectAll(payments.map(payment=>payment.id),e)}}/> Select All</th>
                <th>Customer Name</th>
                <th>Currency</th>
                <th>Order Details</th>
                <th>Total Due Amount</th>
                <th>Download Invoice</th>
                <th>Email Invoice To Customer</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr>
                  <td><input type="checkbox"  checked={userIds.includes(payment.id)}  onChange={() => handleCheckboxChange(payment.id)} /></td>
                  <td>{payment.customer_name}</td>
                  <td>{payment.currencyType}</td>
                  <td>
                    {payment.Orders.map((order) => (
                      <>
                        <Popover
                          content={
                            <div>
                              <hr />
                              <p><b>Design Name</b> : {order.design_name}</p>
                              <p><b>Order Type</b> : {order.order_type}</p>
                              <p>
                                <b>Price</b> :{' '}
                                {getCurrencySymbol(payment.currencyType)}{' '}
                                {order.price}
                              </p>
                            </div>
                          }
                          title="Order Details"
                        >
                          <Button type="primary">#{order?.serialNumber}</Button>
                        </Popover>
                        <br /> <br />
                      </>
                    ))}
                  </td>
                  <td>
                    {getCurrencySymbol(payment.currencyType)}{' '}
                    {payment.Orders.reduce(
                      (previousValue, currentValue, currentIndex, array) => {
                        return previousValue + Number(currentValue.price);
                      },
                      0,
                    )}
                  </td>
                  <td>
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={() => {
                        handleDownload(payment.id);
                      }}
                    >
                      Download PDF
                    </Button>
                  </td>
                  <td>
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={() => {
                        emailInvoice(payment.id);
                      }}
                    >
                      Send Invoice
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import HeightWidth from '../Utils/HeightWidth';
import Dropzone from 'react-dropzone';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useParams } from 'react-router-dom';
import getCurrencySymbol from '../Utils/currencySymbol';
import { DeleteOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
export default function ReleaseOrder() {
  const navigate = useNavigate();
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [height, setheight] = useState([]);
  const [width, setwidth] = useState([]);
  const [user_stitches, setuser_stitches] = useState('');
  const [real_stitches, setreal_stitches] = useState(0);
  const [admin_notes, setadmin_notes] = useState('');
  const [price, setprice] = useState(0);
  const [files, setfiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [boxes, setBoxes] = useState([{}]);
  const [employees, setemployees] = useState(null);
  const [employee_id, setemployee_id] = useState(null);
  const [order_type, setorder_type] = useState(null);
  const [order, setorder] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getData = async () => {
      try {
        const { data: orderData } = await axiosPrivate.get(
          `/api/admin/order/${id}`,
          {
            signal: controller.signal,
          },
        );
        setorder(orderData);
        setemployee_id(orderData?.AssignedOrder?.employee_id);
        const { data: employeeData } = await axiosPrivate.get(
          '/api/admin/employee',
          {
            signal: controller.signal,
          },
        );
        let filter = [];
        if (
          orderData.order_type === 'Embroidery Digitizing Order' ||
          orderData.order_type === 'Embroidery Digitizing Quote'
        ) {
          // console.log("in if",orderData.order_type)
          filter = employeeData.filter(
            (employee) => employee.employeeType == 'Digitizer',
          );
        } else if (
          orderData.order_type === 'Vector Art Order' ||
          orderData.order_type === 'Vector Art Quote'
        ) {
          // console.log("in if else",orderData.order_type)
          filter = employeeData.filter(
            (employee) => employee.employeeType == 'Vectorizer',
          );
        } else {
          // console.log("in else",orderData.order_type)
          filter = employeeData;
        }

        setemployees(filter);
      } catch (error) { }
    };

    getData();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const Converter = function (data) {
    let inch = data * 1;
    let cm = (data * 2.54).toFixed(2);
    let mm = (data * 25.4).toFixed(2);

    return { mm, cm, inch };
  };
  const addBox = () => {
    setBoxes([...boxes, { height: '', width: '' }]);
  };

  const handleChange = (index, key, event) => {
    const newBoxes = [...boxes];
    newBoxes[index][key] = event.target.value;
    setBoxes(newBoxes);

    const t_stitches = boxes.reduce(
      (prev, next) => prev + Number(next.stitch),
      0,
    );

    setreal_stitches(t_stitches);
  };

  const handleRemoveInp = (index) => {
    const newBoxes = [...boxes];
    newBoxes.splice(index, 1);
    setBoxes(newBoxes);
  };
  const handleDrop = (acceptedFiles) => {
    console.log('handle drop')
    setErrMsg('');
    if (order.order_type == 'Embroidery Digitizing Quote' ||
      order.order_type == 'Vector Art Quote' ||
      order.order_type == 'Both Embroidery Digitizing & Vector Art Quotes') {
        console.log("inside if order type")
      const [fileExtension] = acceptedFiles.map((file) => {
        return file.name.split('.').pop().toLowerCase();
      });
      console.log(fileExtension)
      console.log(fileExtension !== 'pdf')
      if (fileExtension !== 'jpg' && fileExtension !== 'jpeg') {
        console.log("inside if extension")
        setErrMsg(`Only pdf and jpg files are allowed in quote orders`);
        return;
      } 
    }



    // Calculate the total size of all selected files
    const totalSize =
      selectedFiles.reduce((acc, file) => acc + file.size, 0) +
      acceptedFiles.reduce((acc, file) => acc + file.size, 0);

    // Check if the total size exceeds 100 megabytes
    if (totalSize > 500 * 1024 * 1024) {
      setErrMsg(`File size exceed ${(totalSize / 1024 / 1024).toFixed(2)} MB`);
      return;
    }

    setSelectedFiles([...selectedFiles, ...acceptedFiles]);
  };

  const handleRemove = (index) => {
    let filterfiles = selectedFiles.filter(
      (item, itemindex) => itemindex !== index,
    );
    setSelectedFiles(filterfiles);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const controller = new AbortController();
    const formData = new FormData();
    let isMounted = true;

    const releaseOrder = async () => {
      let isMounted = true;
      const controller = new AbortController();

      try {
        const response = await axiosPrivate.patch(
          `/api/admin/order/release/${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            signal: controller.signal,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              setProgress(percentCompleted);
            },
          },
        );
        isMounted && navigate('/admin/orders');
      } catch (err) {
        console.error(err);
        setIsSubmitting(false);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    formData.append('price', price);
    formData.append('real_stitches', real_stitches);
    formData.append('user_stitches', user_stitches);
    formData.append('admin_notes', admin_notes);
    formData.append('height', JSON.stringify(boxes));
    formData.append('width', width);
    formData.append('employee_id', employee_id);

    if (!selectedFiles.length) {
      setErrMsg('Upload required files before sending');
      setIsSubmitting(false);
    } else {
      selectedFiles.forEach((item) => formData.append('files', item));
      releaseOrder();
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <>
      {order && (
        <div className="container">
          <div className="row my-4">
            <div className="card card-success w-100">
              <div className="card-header">
                <h3 className="card-title">
                  {`Ref # ${order.serialNumber} / ${order.design_name} / ${order.order_type}`}
                </h3>
              </div>
              <div className="card-body">
                <button className="btn btn-success w-100" onClick={addBox}>
                  +
                </button>
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {boxes.map((box, index) => (
                    <div key={index} className="form-group bg-light mt-2 p-3">
                      <button
                        type="button"
                        className="close"
                        onClick={() => handleRemoveInp(index)}
                      >
                        X
                      </button>
                      <div class="row">
                        <div class="col">
                          <label for="">Height</label>
                          <input
                            type="number"
                            value={box.height}
                            placeholder="Height"
                            onChange={(event) =>
                              handleChange(index, 'height', event)
                            }
                            className="form-control form-control-sm"
                          />
                          <label for="" style={{ fontSize: '0.8vw' }}>
                            <span className="border border-secondary px-3">
                              {Converter(box.height).inch} inches
                            </span>
                          </label>
                          <label for="" style={{ fontSize: '0.8vw' }}>
                            <span className="border border-secondary px-3">
                              {Converter(box.height).cm} cm
                            </span>
                          </label>
                          <label for="" style={{ fontSize: '0.8vw' }}>
                            <span className="border border-secondary px-3">
                              {Converter(box.height).mm} mm
                            </span>
                          </label>
                        </div>
                        <div class="col">
                          <label for="" className="d-block">
                            Width
                          </label>
                          <input
                            type="number"
                            value={box.width}
                            placeholder="Width"
                            onChange={(event) =>
                              handleChange(index, 'width', event)
                            }
                            className="form-control form-control-sm"
                            defaultValue={0}
                          />
                          <label for="" style={{ fontSize: '0.8vw' }}>
                            <span className="border border-secondary px-3">
                              {Converter(box.width).inch} inches
                            </span>
                          </label>
                          <label for="" style={{ fontSize: '0.8vw' }}>
                            <span className="border border-secondary px-3">
                              {Converter(box.width).cm} cm
                            </span>
                          </label>
                          <label for="" style={{ fontSize: '0.8vw' }}>
                            <span className="border border-secondary px-3">
                              {Converter(box.width).mm} mm
                            </span>
                          </label>
                        </div>
                        <div class="col">
                          <label for="" className="d-block">
                            Stiches
                          </label>
                          <input
                            type="number"
                            value={box.stitch}
                            placeholder="stitches"
                            onChange={(event) =>
                              handleChange(index, 'stitch', event)
                            }
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="form-group">
                    <label for="">Real Stiches (To charge the customer)</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Real Stiches (To charge the customer)"
                      // onChange={(e)=>{setreal_stitches(e.target.value)}}
                      value={boxes.reduce(
                        (prev, next) => prev + Number(next.stitch),
                        0,
                      )}
                    />
                  </div>
                  {employees && (
                    <div className="form-group">
                      <label for="">
                        {order_type === 'Embroidery Digitizing Order' ||
                          order_type === 'Embroidery Digitizing Quote'
                          ? 'Digitizers'
                          : order_type === 'Vector Art Order' ||
                            order_type === 'Vector Art Quote'
                            ? 'Vectorizers'
                            : 'Vectorizers/Digitizer'}
                      </label>
                      <select
                        class="form-control"
                        defaultValue={order?.AssignedOrder?.employee_id}
                        onChange={(e) => {
                          setemployee_id(e.target.value);
                        }}
                      >
                        {employees &&
                          employees.map((employee) => (
                            <option value={employee.id}>
                              {employee.username}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                  <div className="form-group">
                    <label for="">Admin Notes (if Any)</label>
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder="Enter Additional Notes"
                      onChange={(e) => {
                        setadmin_notes(e.target.value);
                      }}
                      defaultValue={
                        order.order_type == 'Embroidery Digitizing Quote' ||
                          order.order_type == 'Vector Art Quote' ||
                          order.order_type ==
                          'Both Embroidery Digitizing & Vector Art Quotes'
                          ? 'This quote is provided for free and you can easily convert this quote to order for $ 0.00 (USD) only.'
                          : ''
                      }
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label for="">Price</label>
                  </div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        {getCurrencySymbol(order.Customer.currencyType)}
                      </span>
                    </div>

                    {order.order_type === 'Embroidery Digitizing Quote' ||
                      order.order_type === 'Vector Art Quote' ||
                      order.order_type ===
                      'Both Embroidery Digitizing & Vector Art Quotes' ? (
                      <input
                        className="form-control"
                        type="number"
                        onChange={(e) => {
                          setprice(e.target.value);
                        }}
                        disabled
                        defaultValue={0.0}
                      />
                    ) : (
                      <input
                        className="form-control"
                        type="number"
                        onChange={(e) => {
                          setprice(e.target.value);
                        }}
                        defaultValue={0.0}
                      />
                    )}
                    <div className="input-group-append">
                      <span className="input-group-text">.00</span>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="form-label">Upload files</label>
                    <div class="bg-danger p-2 rounded rounded-3">
                      Warning! Upload multiple files but size not exceed more
                      than 500 MB
                    </div>
                    <Dropzone
                      onDrop={handleDrop}
                      multiple
                      maxSize={500 * 1024 * 1024}

                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p className="btn btn-primary my-2 w-100">
                            Drag and drop files here, or click to select files
                          </p>
                        </div>
                      )}
                    </Dropzone>
                    {selectedFiles.length > 0 && (
                      <div>
                        <h4>Selected Files:</h4>

                        <ul className="list-unstyled">
                          {selectedFiles.map((file, index) => (
                            <li className="list-group-item" key={file.name}>
                              {file.name}
                              <button
                                type="button"
                                className="btn text-danger float-right"
                                onClick={() => handleRemove(index)}
                              >
                                <DeleteOutlined />
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="text-center">
                    <h3 className="text-white bg-secondary rounded rounded-3">
                      Order No. #{order.serialNumber}
                    </h3>
                    <button
                      type="submit"
                      className="btn btn-success my-3 w-50"
                      disabled={isSubmitting}
                    >
                      Upload Order
                    </button>
                    <br />
                    {isSubmitting && <Progress steps={3} percent={progress} size={20} />}
                  </div>
                </form>
                {errMsg && (
                  <div className="bg-danger text-center rounded rounded-pill">
                    Error: {errMsg}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React from 'react'
import useAuth from '../hooks/useAuth'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const RequireAuth =({allowedRoles})=>{

    const {auth} = useAuth()

    const location = useLocation()

    return(
        auth?.roles==allowedRoles
            ? <Outlet/>:
            auth?.accessToken //changed from user to accessToken to persist login after refresh
            ?<Navigate to="/unauthorized" state={{from:location}} replace />:
            <Navigate to="/login" state={{from:location}} replace />
    );
 

}

export default RequireAuth;
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';
import FormInput from './Utils/FormInput';
import { ROLES } from '../App';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
  UnlockFilled
} from '@ant-design/icons';
import { Input ,notification} from 'antd';

export default function Login() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setemail] = useState(false);
  const [password, setpassword] = useState(false);
 
  

  let handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    

    try {
      const response = await axios.post('/api/user/login',{email,password}, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const username = response?.data?.username;
      setAuth({ email, password, roles, accessToken, username });
    
      roles == ROLES.Admin ? navigate('/admin') : navigate('/customer');
      setErrMsg('');
    } catch (error) {
  
      setErrMsg(error.response.data.error);
      setIsSubmitting(false);
    }
  };
  const handleForget=async()=>{
    
    setIsSubmitting(true);
    

    try {
      const response = await axios.post('/api/user/reset',{email}, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      await notification.open({
        message: response.data.message,
        // description:
        //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        // onClick: () => {
        //   console.log('Notification Clicked!');
        // },
      });
      setErrMsg('');
      setIsSubmitting(false);
    } catch (error) {
  
      setErrMsg(error.response.data.error);
      setIsSubmitting(false);
    }


  }
 
  return (
    <div className="bg-log">
      <div className="container-fluid">
        <div className="row justify-content-center" style={{ height: '100vh' }}>
          <div className="col-12 col-md-5 align-self-center">
            <h1 className="text-center gra-text">
              AA Creative EMB <br /> Customer Portal
            </h1>
            <div className="card card-success bg-card-ini text-white ">
              <div className="card-body ">
                <h3 className="text-center gra-text" style={{fontSize:"8vw",color:'whitesmoke'}}><UnlockFilled /> </h3>
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="formInput">
                    <label className="form-label">Email</label>
                    <Input
                      size="large"
                      placeholder="Enter Email"
                      prefix={<UserOutlined />}
                      onChange={(e)=>{setemail(e.target.value)}}
                    />
                  </div>
                  <div className="formInput">
                    <label className="form-label">Password</label>
                    <Input.Password
                      size="large"
                      placeholder="Enter Password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      onChange={(e)=>{setpassword(e.target.value)}}
                    />
                    <button type='button' className='btn btn-anchor' onClick={()=>{handleForget()}} disabled={isSubmitting} >Forget Password</button>
                  </div>
                  <div className="text-center my-4">
                    <button
                      className="btn btn-primary btn-log w-50"
                      disabled={isSubmitting}
                    >
                      Login
                    </button>
                  </div>
                  <Link
                    to={'/register'}
                    className="my-4 text-center text-white font-weight-bolder d-block"
                  >
                    Dont have an Account
                  </Link>
                </form>
                {errMsg && (
                  <div className="bg-danger text-center rounded rounded-pill">
                    {errMsg}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
